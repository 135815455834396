export class Archiv {
  id: number;
  archivname: string;
  mandantid: number;
  mandant: string;
  archivrechte: string;

  constructor() {
    this.id = 0;
    this.archivname = '';
    this.mandantid = 0;
    this.mandant = '';
    this.archivrechte = '';
  }

  getArchivrechte(): ArchivRechte {
    const rechte = new ArchivRechte();

    if (this.archivrechte.length >= 20) {
      rechte.dokumentRead = this.archivrechte[0] === '1';
      rechte.dokumentUpdate = this.archivrechte[1] === '1';
      rechte.dokumentCreate = this.archivrechte[2] === '1';
      rechte.dokumentDelete = this.archivrechte[3] === '1';

      rechte.ordnerRead = this.archivrechte[4] === '1';
      rechte.ordnerUpdate = this.archivrechte[5] === '1';
      rechte.ordnerCreate = this.archivrechte[6] === '1';
      rechte.ordnerDelete = this.archivrechte[7] === '1';

      rechte.adminVorlagen = this.archivrechte[8] === '1';
      rechte.adminSuchUndSchlagwort = this.archivrechte[9] === '1';
      rechte.adminTitelUndEmail = this.archivrechte[10] === '1';
      rechte.adminDeletedObjekte = this.archivrechte[11] === '1';

      rechte.klassenReplikation = this.archivrechte[12] === '1';
      rechte.klassenAdmin = this.archivrechte[13] === '1';

      rechte.klassenFrei1 = this.archivrechte[14] === '1';
      rechte.klassenFrei2 = this.archivrechte[15] === '1';
      rechte.klassenFrei3 = this.archivrechte[16] === '1';
      rechte.klassenFrei4 = this.archivrechte[17] === '1';
      rechte.klassenFrei5 = this.archivrechte[18] === '1';
      rechte.klassenFrei6 = this.archivrechte[19] === '1';
    }

    return rechte;
  }
}

export class ArchivRechte {
  dokumentRead: boolean = false;
  dokumentUpdate: boolean = false;
  dokumentCreate: boolean = false;
  dokumentDelete: boolean = false;

  ordnerRead: boolean = false;
  ordnerUpdate: boolean = false;
  ordnerCreate: boolean = false;
  ordnerDelete: boolean = false;

  adminVorlagen: boolean = false;
  adminSuchUndSchlagwort: boolean = false;
  adminTitelUndEmail: boolean = false;
  adminDeletedObjekte: boolean = false;

  klassenReplikation: boolean = false;
  klassenAdmin: boolean = false;

  klassenFrei1: boolean = false;
  klassenFrei2: boolean = false;
  klassenFrei3: boolean = false;
  klassenFrei4: boolean = false;
  klassenFrei5: boolean = false;
  klassenFrei6: boolean = false;
}

export class Mandant {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}
