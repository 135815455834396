import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions";

import { RootState } from '@/store';

import { State } from "./state"
import ArchivApiService from "@/service/ArchivApiService";
import router from "@/router";

export enum ActionTypes {
  GetSessions = "GETSESSIONS",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetSessions](context: ActionArguments): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.GetSessions]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status
    status.sessionLoading = true
    status.sessionErrorMsg == ""
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.getSessions()
      .then(() => {
        status.sessionLoading = false
        dispatch(CommonActionTypes.SetStatus, status);
        router.push("/");
      })
      .catch(error => {
        status.sessionLoading = false
        status.sessionErrorMsg == error.response.data.title
        dispatch(CommonActionTypes.SetStatus, status);
      })
  }
}