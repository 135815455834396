import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";

import { RootState } from '@/store';

import { State } from "./state"

export enum ActionTypes {
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
}

export const actions: ActionTree<State, RootState> & Actions = {
}