<template>
  <!-- New Doc Modal -->
  <div
    class="modal fade"
    :id="'newCompModal-' + doc.docid"
    tabindex="-1"
    :aria-labelledby="'newCompModal-' + doc.docid"
    aria-hidden="true"
    ref="newCompModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header py-1">
          <span class="modal-title my-auto" :id="'newCompModalLabel-' + doc.docid">Componente hinzufügen</span>
          <button type="button" class="btn-close" aria-label="Close" @click="closeModal()"></button>
        </div>
        <div class="modal-body">
          <LoadingSpinner v-if="loading" text="...Componente wird erstellt..." />

          <form v-else @submit.prevent="createNewComp()">
            <div class="mb-3">
              <label for="fileupload" class="form-label">Datei</label>
              <input type="file" class="form-control" id="fileupload" @change="handleFileUpload" />
            </div>

            <div v-if="fileSizeErrorMessage != ''" class="alert alert-danger mb-3">
              {{ fileSizeErrorMessage }}
            </div>

            <div class="mb-3">
              <label :for="'newCompTitel-' + doc.docid" class="form-label">Titel</label>
              <input type="text" class="form-control" :id="'newCompTitel-' + doc.docid" v-model="newComp.titel" />
            </div>
            <p class="text-danger" v-show="newCompTitelError != ''">{{ newCompTitelError }}</p>

            <div v-if="errorMsg != ''" class="alert alert-danger">{{ errorMsg }}</div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-secondary me-3" @click="closeModal()">Abbrechen</button>
              <button type="submit" class="btn btn-primary">Speichern</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, ref, computed } from 'vue';
  import { useStore } from '@/store';
  import { Modal } from 'bootstrap';

  import { Comp, CreateCompData, CreateCompRequest } from '@/models/CompModels';
  import { Doc } from '@/models/DocModels';

  import { ActionTypes as OrdnerActionTypes } from '@/store/modules/Ordner/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  //#endregion

  const props = defineProps({
    archivid: {
      type: Number as PropType<number>,
      required: true,
    },
    doc: {
      type: Object as PropType<Doc>,
      required: true,
    },
  });

  const store = useStore();

  const loading = computed(() => store.getters.status.createCompLoading);
  const errorMsg = computed(() => store.getters.status.compErrorMsg);

  const newCompTitelError = ref('');
  const newCompModalRef = ref();

  const newComp = ref(new Comp());

  const MAX_FILE_SIZE = 50 * 1024 * 1024;
  const file = ref<File | null>(null);
  const fileSizeErrorMessage = ref('');

  function handleFileUpload(event: Event) {
    fileSizeErrorMessage.value = '';

    const inputElement = event.target as HTMLInputElement;
    const selectedfile = inputElement.files![0];

    if (selectedfile) {
      file.value = selectedfile;

      if (newComp.value.titel == '') {
        newComp.value.titel = selectedfile.name;
      }
    }

    if (file.value != null && file.value.size > MAX_FILE_SIZE) {
      fileSizeErrorMessage.value = 'Die Anlagengröße überschreitet das erlaubte Maximum. Die maximale Größe ist 25MB.';
    }
  }

  function closeModal() {
    newComp.value = new Comp();
    newCompTitelError.value = '';
    fileSizeErrorMessage.value = '';
    file.value = null;
    Modal.getInstance(newCompModalRef.value)?.hide();
  }

  function showModal() {
    if (newCompModalRef.value) {
      new Modal(newCompModalRef.value).show();
    }
  }

  function createNewComp() {
    newCompTitelError.value = '';
    fileSizeErrorMessage.value = '';

    if (file.value == null || file.value.size > MAX_FILE_SIZE) {
      fileSizeErrorMessage.value = 'Bitte wählen Sie eine Datei aus';
      return;
    }
    if (newComp.value.titel == '') {
      newCompTitelError.value = 'Bitte geben Sie einen Titel ein';
      return;
    }

    newComp.value.docid = props.doc.docid;
    newComp.value.sortid = props.doc.comps ? props.doc.comps.length + 1 : 1;

    const data: CreateCompData = {
      comp: JSON.parse(JSON.stringify(newComp.value)),
      file: file.value,
    };

    const request: CreateCompRequest = {
      data: data,
      archivid: props.archivid,
    };

    store.dispatch(OrdnerActionTypes.CreateComp, request).then(() => {
      if (errorMsg.value != '') {
        newCompTitelError.value = 'Es ist ein Fehler aufgetreten';
      } else {
        closeModal();
      }
    });
  }

  defineExpose({
    showModal,
  });
</script>
