import { MutationTree } from 'vuex';
import { State } from './state';
import { Status } from '@/models/CommonModels';

export enum MutationTypes {
  SetStatus = 'SETSTATUS',
  SetActiveMenu = 'SETACTIVEMENU',
}

export type Mutations = {
  [MutationTypes.SetStatus](state: State, status: Status): void;
  [MutationTypes.SetActiveMenu](state: State, activeMenu: string | null): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetStatus](state: State, status: Status) {
    state.status = status;
  },

  [MutationTypes.SetActiveMenu](state: State, activeMenu: string | null) {
    state.activeMenu = activeMenu;
  },
};
