import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";

import { RootState } from '@/store';

import { State } from "./state"
import ArchivApiService from "@/service/ArchivApiService";
import { ActionTypes as OrdnerActionTypes } from "@/store/modules/Ordner/actions";
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions";
import { Archiv, Mandant } from "@/models/ArchivModels";
import router from "@/router";

export enum ActionTypes {
  GetArchive = "GETARCHIVE",
  SetSelectedArchiv = "SETSELECTEDARCHIV",
  SetSelectedMandant = "SETSELECTEDMANDANT"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetArchive](context: ActionArguments): Promise<void>
  [ActionTypes.SetSelectedArchiv](context: ActionArguments, data: Archiv | undefined): void
  [ActionTypes.SetSelectedMandant](context: ActionArguments, data: Mandant): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {

  [ActionTypes.GetArchive]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status
    status.archivLoading = true
    status.archivErrorMsg == ""
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.getArchivesBySessionid()
      .then(res => {
        var archive: Array<Archiv> = res.data;
        var mandanten: Array<Mandant> = [];

        archive.forEach(a => {
          if (!mandanten.some(m => m.id == a.mandantid)) {
            mandanten.push(new Mandant(a.mandantid, a.mandant));
          }
        })

        commit(MutationTypes.SetMandanten, mandanten);
        commit(MutationTypes.SetSelectedMandant, mandanten[0]);
        commit(MutationTypes.GetArchiveSuccess, archive);

        status.archivLoading = false
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .catch(error => {
        status.archivLoading = false
        status.archivErrorMsg == error.response.data.title
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  [ActionTypes.SetSelectedArchiv]({commit}, data) {
    commit(MutationTypes.SetSelectedArchiv, data);
  },

  [ActionTypes.SetSelectedMandant]({commit, dispatch}, data) {
    commit(MutationTypes.SetSelectedMandant, data);
    dispatch(OrdnerActionTypes.ClearOrdner, undefined);
    return new Promise((res, rej) => { res()});
  }
}