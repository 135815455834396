import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Ordner } from "@/models/OrdnerModels";

export type Getters = {
  ordner(state: State): Array<Ordner>
  currentOrdner(state: State): Array<Ordner>
}

export const getters: GetterTree<State, RootState> & Getters = {
  ordner(state) {
    return state.ordner;
  },

  currentOrdner(state) {
    return state.currentOrdner;
  }
}
