import { Archiv, Mandant } from "@/models/ArchivModels"

export type State = {
  archive: Array<Archiv>,
  selectedArchiv: Archiv | undefined,
  mandanten: Array<Mandant>,
  selectedMandant: Mandant,
}

export const state: State = {
  // archive: sessionStorage.getItem("archive") != null && sessionStorage.getItem("archive") !== undefined ? JSON.parse(sessionStorage.archive) : new Array<Archiv>(),
  archive: new Array<Archiv>(),
  selectedArchiv: undefined,
  mandanten: new Array<Mandant>(),
  selectedMandant: new Mandant(0, "undefined"),
}
