<template>
  <div v-show="isVisible" class="toast fade show mb-2" :class="background" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="d-flex">
      <div v-if="loading" class="spinner-border spinner-border-sm my-auto mx-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="toast-body">{{ message }}</div>
      <button type="button" class="btn-close btn-close-white me-2 m-auto" @click="dismiss" aria-label="Close"></button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType } from 'vue';

  const { message, background } = defineProps({
    message: {
      type: String as PropType<string>,
      required: true,
    },
    background: {
      type: String as PropType<string>,
      default: 'text-bg-primary',
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  });

  const emit = defineEmits<{
    (e: 'dismiss'): void;
  }>();

  const isVisible = ref(true);

  const dismiss = () => {
    isVisible.value = false;
    emit('dismiss');
  };
</script>
