import { MutationTree } from "vuex";
import { State } from "./state";

export enum MutationTypes {

}


export type Mutations = {

}

export const mutations: MutationTree<State> & Mutations = {

}