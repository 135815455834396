export class GetCompsRequest {
  archivid: number;
  docid: string;

  constructor(archivid: number, docid: string) {
    this.archivid = archivid;
    this.docid = docid;
  }
}

export class DownloadCompData {
  archivname: string;
  archivmandantid: number;
  componente: Comp;

  constructor() {
    this.archivname = '';
    this.archivmandantid = 0;
    this.componente = new Comp();
  }
}

export class DownloadCompRequest {
  archivname: string;
  archivmandantid: number;
  docguid: string;
  compguid: string;

  constructor(archivmandantid: number, archivname: string, docguid: string, compguid: string) {
    this.archivname = archivname;
    this.archivmandantid = archivmandantid;
    this.docguid = docguid;
    this.compguid = compguid;
  }
}

export class ArchivComponenteFile {
  filesize: number;
  filecontentAsBase64: string;

  constructor(filesize: number = 0, data: string = '') {
    this.filesize = filesize;
    this.filecontentAsBase64 = data;
  }
}

export class Comp {
  id: number;
  sortid: number;
  compid: string;
  docid: string;
  filename: string;
  // extvolname: string;
  // extfilename: string;
  contenttype: string;
  charset: string;
  adate: Date;
  mdate: Date;
  vdate: Date;
  edate: Date;
  ddate: Date;
  // alversion: string;
  // version: string;
  barcode: string;
  titel: string;
  autor: string;
  // such_0: string;
  // such_1: string;
  // such_2: string;
  // such_3: string;
  // such_4: string;
  // such_5: string;
  // such_6: string;
  // such_7: string;
  // such_8: string;
  // such_9: string;
  // status: number;
  fileversion: number;
  cryid: number;
  keyid: number;
  file: ArchivComponenteFile;

  constructor() {
    this.id = 0;
    this.sortid = 1;
    this.compid = '';
    this.docid = '';
    this.filename = '';
    // this.extvolname = "",
    // this.extfilename = "",
    this.contenttype = '';
    this.charset = '';
    this.adate = new Date();
    this.mdate = new Date();
    this.vdate = new Date();
    this.edate = new Date();
    this.ddate = new Date();
    // this.alversion = '';
    // this.version = '0';
    this.barcode = '';
    this.titel = '';
    this.autor = '';
    // this.such_0 = '';
    // this.such_1 = '';
    // this.such_2 = '';
    // this.such_3 = '';
    // this.such_4 = '';
    // this.such_5 = '';
    // this.such_6 = '';
    // this.such_7 = '';
    // this.such_8 = '';
    // this.such_9 = '';
    // this.status = 0;
    this.fileversion = 0;
    this.cryid = 0;
    this.keyid = 0;
    this.file = new ArchivComponenteFile();
  }
}

export interface CreateCompData {
  comp: Comp;
  file: File | null;
}

export interface CreateCompRequest {
  archivid: number;
  data: CreateCompData;
}

export class CRDCompRequest {
  archivid: number;
  comp: Comp;

  constructor(comp: Comp, archivid: number) {
    this.comp = comp;
    this.archivid = archivid;
  }
}

export interface UploadCompFileRequest {
  archivid: number;
  data: UploadCompFileData;
}

export interface UploadCompFileData {
  compid: string;
  file: File | null;
}
