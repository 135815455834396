<template>
  <div class="row py-2 m-0 folder-row" @contextmenu.prevent="showContextMenu">
    <div class="col-7">
      <input :id="'folder-row-select_' + ord.id" class="form-check-input me-4" type="checkbox" value="" />

      <div v-if="deleteOrdnerLoading && thisOrdnerDeleteLoading" class="spinner-border spinner-border-sm text-primary me-4"></div>
      <font-awesome-icon v-else icon="folder-open" class="text-primary me-4" />

      <span @click="getOrdnerElements" class="folder-titel me-4">{{ ord.titel }}</span>
    </div>

    <div class="col-1">
      <div class="dropdown">
        <button type="button" class="btn btn-sm py-0 folder-menu-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <font-awesome-icon icon="ellipsis-vertical" class="text-body-tertiary" />
        </button>

        <ul class="dropdown-menu m-0 p-0 rounded-0">
          <li class="dropdown-item" :class="{ disabled: !rechte.ordnerUpdate }" @click="showUpdateModal()">Bearbeiten</li>
          <li class="dropdown-item" :class="{ disabled: !rechte.ordnerDelete }" @click="showDeleteModal()">Löschen</li>
        </ul>
      </div>
    </div>

    <div class="col-2">
      {{ ord.barcode }}
    </div>

    <div class="col-2 text-end">
      <span>{{ dateConverter }}</span>
    </div>
  </div>
  <!-- <button type="button" class="btn btn-outline-secondary btn-sm" @click="showUpdateModal()">Update</button>
  <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteOrdner()">Löschen</button> -->

  <!-- Update Modal -->
  <div
    class="modal fade"
    :id="'updateOrdnerModal' + ord.id"
    tabindex="-1"
    :aria-labelledby="'updateOrdnerModal' + ord.id"
    aria-hidden="true"
    ref="updateOrdnerModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header py-1">
          <span class="modal-title my-auto" :id="'updateOrdnerModalLabel' + ord.id"> Ordner bearbeiten </span>

          <button type="button" class="btn-close btn-sm" @click="closeUpdateModal()"></button>
        </div>

        <div class="modal-body" :id="'updateOrdnerModalBody' + ord.id">
          <LoadingSpinner v-if="updateLoading" />

          <div v-else>
            <div class="mb-3">
              <label :for="'updateFolderTitelInput_' + ord.id" class="form-label">Titel</label>
              <input type="text" class="form-control" :id="'updateFolderTitelInput_' + ord.id" v-model="updateOrdner.titel" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_1Input_' + ord.id" class="form-label">Titel 1</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_1Input_' + ord.id" v-model="updateOrdner.titel_1" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_2Input_' + ord.id" class="form-label">Titel 2</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_2Input_' + ord.id" v-model="updateOrdner.titel_2" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_3Input_' + ord.id" class="form-label">Titel 3</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_3Input_' + ord.id" v-model="updateOrdner.titel_3" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_4Input_' + ord.id" class="form-label">Titel 4</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_4Input_' + ord.id" v-model="updateOrdner.titel_4" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_5Input_' + ord.id" class="form-label">Titel 5</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_5Input_' + ord.id" v-model="updateOrdner.titel_5" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderBarcodeInput_' + ord.id" class="form-label">Barcode</label>
              <input type="text" class="form-control" :id="'updateFolderBarcodeInput_' + ord.id" v-model="updateOrdner.barcode" />
            </div>
          </div>

          <div v-if="updateErrorMsg != null && updateErrorMsg != '' && showUpdateErrorMsg" class="alert alert-danger">
            <!-- {{ updateErrorMsg }} -->
            <!-- <br> -->
            Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
          </div>
        </div>

        <div class="modal-footer" v-if="!updateLoading">
          <button type="button" class="btn btn-secondary btn-sm" @click="closeUpdateModal()">Abbrechen</button>
          <button type="button" class="btn btn-primary btn-sm" @click="saveChanges()">Änderungen speichern</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete Modal -->
  <div
    class="modal fade"
    :id="'deleteOrdnerModal' + ord.id"
    tabindex="-1"
    :aria-labelledby="'deleteOrdnerModal' + ord.id"
    aria-hidden="true"
    ref="deleteOrdnerModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-danger text-white py-1">
          <strong class="modal-title my-auto" :id="'deleteOrdnerModalLabel' + ord.id">Ordner löschen </strong>

          <button type="button" class="btn-close btn-sm" @click="closeDeleteModal()"></button>
        </div>

        <div class="modal-body" :id="'deleteOrdnerModalBody' + ord.id">
          <LoadingSpinner v-if="deleteOrdnerLoading" />

          <div v-else>
            <p>
              Der Ordner <strong class="text-danger">{{ ord.titel }}</strong> sowie alle Unterordner und Dokumente werden gelöscht
            </p>
          </div>

          <div v-if="deleteOrdnerErrorMsg != null && deleteOrdnerErrorMsg != '' && showDeleteErrorMsg" class="alert alert-danger">
            <!-- {{ updateErrorMsg }} -->
            <!-- <br> -->
            Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
          </div>
        </div>

        <div class="modal-footer" v-if="!deleteOrdnerLoading">
          <button type="button" class="btn btn-secondary btn-sm" @click="closeDeleteModal()">Abbrechen</button>
          <button type="button" class="btn btn-danger btn-sm" @click="deleteOrdner()">Ordner löschen</button>
        </div>
      </div>
    </div>
  </div>

  <ContextMenu ref="contextMenuRef" :items="contextMenuItems" :menuId="'ordner-context-menu-' + ord.id" />
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed, ref, onMounted } from 'vue';
  import { Modal } from 'bootstrap';
  import { useStore } from '@/store';
  import { archivHelperfunction } from '@/utils/ArchivFunctions';

  import ContextMenu from '@/components/ContextMenu.vue';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';

  import { GetOrdnerRequest, Ordner, CRDOrdnerRequest } from '@/models/OrdnerModels';
  import { GetDocsRequest } from '@/models/DocModels';

  import { ActionTypes as OrdnerActionTypes } from '@/store/modules/Ordner/actions';
  import { Archiv, ArchivRechte } from '@/models/ArchivModels';
  //#endregion

  const props = defineProps({
    ord: {
      type: Object as PropType<Ordner>,
      required: true,
    },
    archivid: {
      type: Number,
      required: true,
    },
  });

  const { getArchivrechte } = archivHelperfunction();

  const store = useStore();

  const dateConverter = computed(() => {
    if (props.ord.ddate != null) {
      const ddate = new Date(props.ord.ddate);
      return ('0' + ddate.getDate()).slice(-2) + '.' + ('0' + (ddate.getMonth() + 1)).slice(-2) + '.' + ddate.getFullYear();
    } else {
      return '';
    }
  });

  const archiv = computed(() => store.getters.archive.find((a: Archiv) => a.id == props.archivid));
  const rechte = computed(() => {
    if (archiv.value) {
      return getArchivrechte(archiv.value.archivrechte);
    } else {
      return new ArchivRechte();
    }
  });

  //#region ContextMenu
  const contextMenuRef = ref();
  function showContextMenu(event: MouseEvent) {
    event.stopPropagation();

    if (contextMenuRef.value) {
      contextMenuRef.value.showMenu(event.clientX, event.clientY);
    }
  }

  const contextMenuItems = ref<Array<any>>([]);

  function setContextMenuItems() {
    // Edit Ordner
    contextMenuItems.value.push({ label: 'Bearbeiten', action: () => showUpdateModal(), disabled: !rechte.value.ordnerUpdate });

    // Delete Ordner
    contextMenuItems.value.push({ label: 'Löschen', action: () => showDeleteModal(), disabled: !rechte.value.ordnerDelete });
  }
  //#endregion

  //#region Update Ordner
  const updateLoading = computed(() => store.getters.status.updateOrdnerLoading);
  const updateErrorMsg = computed(() => store.getters.status.updateOrdnerErrorMsg);
  const showUpdateErrorMsg = ref(false);

  // const updateOrdner = reactive({
  //   titel: props.ord.titel,
  //   titel_1: props.ord.titel_1,
  //   titel_2: props.ord.titel_2,
  //   titel_3: props.ord.titel_3,
  //   titel_4: props.ord.titel_4,
  //   titel_5: props.ord.titel_5,
  //   barcode: props.ord.barcode,
  // });

  const updateOrdner = ref(JSON.parse(JSON.stringify(props.ord)));

  function resetForm() {
    updateOrdner.value = JSON.parse(JSON.stringify(props.ord));
  }

  const updateOrdnerModalRef = ref();
  function closeUpdateModal() {
    resetForm();
    Modal.getInstance(updateOrdnerModalRef.value)?.hide();
  }

  async function showUpdateModal() {
    showUpdateErrorMsg.value = false;

    if (updateOrdnerModalRef.value) {
      new Modal(updateOrdnerModalRef.value).show();
    }
  }

  function saveChanges() {
    showUpdateErrorMsg.value = false;

    const request = new CRDOrdnerRequest(updateOrdner.value, props.archivid);

    store.dispatch(OrdnerActionTypes.UpdateOrdner, request).then(() => {
      if (updateErrorMsg.value == null || updateErrorMsg.value == '') {
        closeUpdateModal();
      } else {
        showUpdateErrorMsg.value = true;
      }
    });
  }
  //#endregion

  //#region Delete Ordner
  const deleteOrdnerLoading = computed(() => store.getters.status.deleteOrdnerLoading);
  const thisOrdnerDeleteLoading = ref(false);
  const deleteOrdnerErrorMsg = computed(() => store.getters.status.deleteOrdnerErrorMsg);
  const showDeleteErrorMsg = ref(false);

  const deleteOrdnerModalRef = ref();
  function closeDeleteModal() {
    Modal.getInstance(deleteOrdnerModalRef.value)?.hide();
  }

  async function showDeleteModal() {
    showDeleteErrorMsg.value = false;

    if (deleteOrdnerModalRef.value) {
      new Modal(deleteOrdnerModalRef.value).show();
    }
  }

  function deleteOrdner() {
    thisOrdnerDeleteLoading.value = true;

    const request = new CRDOrdnerRequest(props.ord, props.archivid);
    closeDeleteModal();
    store
      .dispatch(OrdnerActionTypes.DeleteOrdner, request)
      .then(() => {
        if (deleteOrdnerErrorMsg != null && deleteOrdnerErrorMsg.value != '') {
          showDeleteModal();
          showDeleteErrorMsg.value = true;
        }
      })
      .finally(() => {
        thisOrdnerDeleteLoading.value = false;
      });
  }
  //#endregion

  function getOrdnerElements() {
    store.dispatch(OrdnerActionTypes.SetCurrentOrdner, props.ord);

    if (props.archivid && (props.ord.ordner == null || props.ord.ordner?.length == 0)) {
      store.dispatch(OrdnerActionTypes.GetOrdner, new GetOrdnerRequest(props.archivid, props.ord.ordid));
    }

    if (props.archivid && (props.ord.docs == null || props.ord.docs?.length == 0)) {
      store.dispatch(OrdnerActionTypes.GetDocs, new GetDocsRequest(props.archivid, props.ord.ordid));
    }
  }

  onMounted(() => {
    setContextMenuItems();
  });
</script>

<style lang="scss" scoped>
  .folder-row:hover {
    background: #f8f9fa;
  }
  .folder-titel:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .folder-row .folder-menu-btn {
    visibility: hidden;
  }

  .folder-row:hover .folder-menu-btn {
    visibility: visible;
  }

  .folder-menu-btn:hover {
    background: rgb(221, 221, 221);
  }

  .folder-menu-btn:active {
    background: rgb(221, 221, 221) !important;
    border-color: transparent !important;
  }

  .folder-menu-btn:focus {
    background: rgb(221, 221, 221) !important;
    border-color: transparent !important;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-item:hover {
    cursor: pointer;
    background-color: rgb(221, 221, 221);
  }
</style>
