<template>
    <div id="contentArea">
      <LoadingSpinner v-if="archivLoading || sessionLoading" :text="'...Archive werden geladen...'" />
      <!-- <h1 class="mb-4 fs-4">Archive</h1> -->
      <div v-else class="d-flex flex-row flex-wrap ">
        <div class="mb-3 me-3 p-2 text-center archiv-size" v-for="archiv in filteredArchive" :key="archiv.id">
          <router-link :to="'/archiv/' + archiv.archivname + '?id=' + archiv.id" class="nav-link" active-class="active">
            <font-awesome-icon icon="box-archive" class="mx-auto fa-3x" /><br>
            {{archiv.archivname}}
          </router-link>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
// import Navbar from '../components/Navbar.vue'
// import Sidebar from '../components/Sidebar.vue'
// import MainContent from '../components/Home/Content/MainContent.vue'

import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { ActionTypes as ArchivActionTypes } from '@/store/modules/Archiv/actions'

import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default defineComponent ({
  name: "Home",
  components: {
    LoadingSpinner
    // Navbar,
    // Sidebar,
    // MainContent
  },

  setup () {
    const store = useStore();

    const archivLoading = computed(() => store.getters.status.archivLoading)
    const sessionLoading = computed(() => store.getters.status.sessionLoading)

    const selectedMandant = computed(() => store.getters.selectedMandant)
    const archive = computed(() => store.getters.archive);
    const filteredArchive = computed(() => archive.value.filter(a => a.mandantid == selectedMandant.value.id).sort((a, b) => a.archivname.toLowerCase() < b.archivname.toLowerCase() ? -1 : 1));

    return {
      store,
      archivLoading,
      sessionLoading,
      selectedMandant,
      filteredArchive,
      archive
    }
  },

  mounted () {
    this.store.dispatch(ArchivActionTypes.SetSelectedArchiv, undefined);
    if (this.archive == null || this.archive.length == 0) {
      this.store.dispatch(ArchivActionTypes.GetArchive, undefined);
    }
  }
})
</script>


<style lang="scss" scoped>
/* #home {
  height: 100vh;
} */

/* #contentArea {
  display: flex;
  margin-top: -61px;
  padding-top: 61px;
  height: 100%;
} */

.archiv-size {
  width: 120px;
  overflow-wrap: break-word;
}

.archiv-size:hover {
  color: #2A579B;
}
</style>