import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Comp } from "@/models/CompModels";

export type Getters = {
  componente(state: State): Comp,
}

export const getters: GetterTree<State, RootState> & Getters = {
  componente(state) {
    return state.componente;
  },
}
