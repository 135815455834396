export class Status {
  authLoading: boolean;
  authErrorMsg: string;

  archivLoading: boolean;
  archivErrorMsg: string;

  compLoading: boolean;
  compDownloadLoading: boolean;
  compLoadingDocid: string;
  createCompLoading: boolean;
  updateCompLoading: boolean;
  deleteCompLoading: boolean;
  compErrorMsg: string;

  docLoading: boolean;
  createDocLoading: boolean;
  updateDocLoading: boolean;
  deleteDocLoading: boolean;
  docErrorMsg: string;

  ordnerLoading: boolean;
  ordnerSidebarLoading: boolean;
  ordnerSidebarLoadingOrdid: string;
  ordnerErrorMsg: string;
  createOrdnerLoading: boolean;
  createOrdnerErrorMsg: string;

  updateOrdnerLoading: boolean;
  updateOrdnerErrorMsg: string;
  deleteOrdnerLoading: boolean;
  deleteOrdnerErrorMsg: string;

  sessionLoading: boolean;
  sessionErrorMsg: string;

  userLoading: boolean;
  userErrorMsg: string;

  sidebarCollapsed: boolean;

  constructor() {
    this.authLoading = false;
    this.authErrorMsg = '';

    this.archivLoading = false;
    this.archivErrorMsg = '';

    //#region COMP
    this.compLoading = false;
    this.compDownloadLoading = false;
    this.compLoadingDocid = '';
    this.createCompLoading = false;
    this.updateCompLoading = false;
    this.deleteCompLoading = false;
    this.compErrorMsg = '';
    //#endregion

    //#region DOC
    this.docLoading = false;
    this.createDocLoading = false;
    this.updateDocLoading = false;
    this.deleteDocLoading = false;
    this.docErrorMsg = '';
    //#endregion

    //#region ORDNER
    this.ordnerLoading = false;
    this.ordnerSidebarLoading = false;
    this.ordnerSidebarLoadingOrdid = '';
    this.ordnerErrorMsg = '';
    this.createOrdnerLoading = false;
    this.createOrdnerErrorMsg = '';
    this.updateOrdnerLoading = false;
    this.updateOrdnerErrorMsg = '';
    this.deleteOrdnerLoading = false;
    this.deleteOrdnerErrorMsg = '';
    //#endregion

    this.sessionLoading = false;
    this.sessionErrorMsg = '';

    this.userLoading = false;
    this.userErrorMsg = '';

    this.sidebarCollapsed = false;
  }
}

export interface ErrorResponseData {
  detail: string;
  status: number;
  title: string;
  type: string;
}
