<template>
  <div v-show="isActive" ref="menu" class="context-menu rounded" :style="{ top: `${position.y}px`, left: `${position.x}px` }" @click="hideMenu">
    <ul class="list-group">
      <li v-for="(item, index) in items" :key="index" class="list-group-item" :class="{ disabled: item.disabled }" @click="handleItemClick(item)">
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, PropType, computed } from 'vue';
  import { useStore } from '@/store';
  import { ActionTypes as CommonActionTypes } from '@/store/modules/Common/actions';

  interface MenuItem {
    label: string;
    disabled: boolean;
    action: () => void;
  }

  const { items, menuId } = defineProps({
    items: {
      type: Array as () => MenuItem[],
      required: true,
    },
    menuId: {
      type: String as PropType<string>,
      required: true,
    },
  });

  const store = useStore();
  // const visible = ref(false);
  const position = ref({ x: 0, y: 0 });
  const menu = ref<HTMLElement | null>(null);

  const isActive = computed(() => store.getters.activeMenu === menuId);

  const showMenu = (x: number, y: number) => {
    store.dispatch(CommonActionTypes.CloseMenu, undefined); // Close any open menu
    position.value = { x, y };
    store.dispatch(CommonActionTypes.ShowMenu, menuId); // Open this menu
  };

  const hideMenu = () => {
    store.dispatch(CommonActionTypes.CloseMenu, undefined); // Close any open menu
  };

  const handleItemClick = (item: MenuItem) => {
    item.action();
    hideMenu();
  };

  onMounted(() => {
    document.addEventListener('click', hideMenu);
  });
  onUnmounted(() => {
    document.removeEventListener('click', hideMenu);
  });

  defineExpose({
    showMenu,
  });
</script>

<style scoped>
  .context-menu {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  .list-group-item {
    cursor: pointer;
  }
  .list-group-item:hover {
    background-color: rgb(221, 221, 221);
  }
</style>
