import { Status } from '@/models/CommonModels';

export type State = {
  status: Status;
  activeMenu: string | null;
};

export const state: State = {
  status: new Status(),
  activeMenu: null,
};
