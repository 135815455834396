import { MutationTree } from "vuex";
import { State } from "./state";

import { Archiv, Mandant } from "@/models/ArchivModels"

export enum MutationTypes {
  SetLoading = "SETLOADING",
  GetArchiveSuccess = "GETARCHIVESUCCESS",
  SetSelectedArchiv = "SETSELECTEDARCHIV",
  ArchivRequestFailure = "ARCHIVREQUESTFAILURE",
  SetMandanten = "SETMANDANTEN",
  SetSelectedMandant = "SETSELECTEDMANDANT",
  ArchivClearError = "ARCHIVCLEARERROR"
}


export type Mutations = {
  [MutationTypes.GetArchiveSuccess](state: State, data: Array<Archiv>): void
  [MutationTypes.SetSelectedArchiv](state: State, data: Archiv | undefined): void
  [MutationTypes.SetMandanten](state: State, data: Array<Mandant>): void
  [MutationTypes.SetSelectedMandant](state: State, data: Mandant): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.GetArchiveSuccess](state, data) {
    state.archive = data;
  },

  [MutationTypes.SetSelectedArchiv](state, data) {
    state.selectedArchiv = data;
  },

  [MutationTypes.SetMandanten](state, data) {
    data.sort((a: Mandant, b: Mandant) => a.name < b.name ? -1 : 1)
    state.mandanten = data;
  },

  [MutationTypes.SetSelectedMandant](state, data) {
    state.selectedMandant = data;
  },
}