<template>
  <nav class="navbar navbar-expand-md bg-white navbar-light shadow-sm pt-2 ps-1 pe-3">
    <div class="container-fluid">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <button class="btn" @click="toggleSidebar()"><font-awesome-icon icon="bars" /></button>
        </li>
      </ul>

      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item dropdown my-auto">
          <img src="@/assets/img/profilbild.png" alt="Profil Bild Rainer Zufall" class="avatar me-2" />

          <a
            class="nav-link dropdown-toggle d-inline pe-0"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >{{user?.benutzer}}</a>

          <ul class="dropdown-menu m-0">
            <li>
              <button class="dropdown-item" @click="logout">
                <font-awesome-icon
                  icon="arrow-right-from-bracket"
                  class="pe-3"
                />
                Abmelden
              </button>
            </li>
          </ul>
        </li>
      </ul>
  </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import { ActionTypes as AuthActionTypes } from "@/store/modules/Auth/actions";
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions"
import { PublicClientApplication, AccountInfo, AuthenticationResult } from '@azure/msal-browser';

export default defineComponent ({
  name: "Navbar",

  setup () {
    const store = useStore();
    const router = useRouter();

    const user = store.getters.authUser
    const azureLogin = computed(() => store.getters.azureLogin);
    const azureUsername = computed(() => store.getters.azureUsername);
    const msalConfig = computed(() => store.getters.msalConfig);
    const msalInstance = msalConfig.value != null ? new PublicClientApplication(msalConfig.value) : null

    return {
      store,
      router,
      user,
      azureLogin,
      azureUsername,
      msalConfig,
      msalInstance
    }

  },

  data () {
    return {
      screenWidth: 0
    }
  },

  methods: {
    logout () {
      // if (this.azureLogin) {
      //   console.log("Azure signout");
      //   this.signOut()
      //     .then(() => {
      //       console.log("LOGOUT");
      //       this.store.dispatch(AuthActionTypes.Logout, undefined);
      //     })
      // }
      // else {
        this.store.dispatch(AuthActionTypes.Logout, undefined)
        .then(() => {
          this.router.replace({name: "Logout"})
        })

      // }
    },

    signOut(): Promise<void> {

      /**
       * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
       */

      if (this.msalConfig != null && this.msalInstance != null) {

        const logoutRequest = {
          account: this.msalInstance.getAccountByUsername(this.azureUsername),
          postLogoutRedirectUri: this.msalConfig.auth.redirectUri,
          mainWindowRedirectUri: this.msalConfig.auth.redirectUri
        };
        
        return this.msalInstance.logoutPopup(logoutRequest);
      }
      else {
        return new Promise<void>((res, rej) => {
          res();
        })
      }
    },

    toggleSidebar () {
      this.store.dispatch(CommonActionTypes.ToggleSidebar, undefined);
    },
  },

  created () {
    this.screenWidth = window.innerWidth;
  }
});
</script>

<style  lang="scss" scoped>
img.avatar {
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #2A579B;
  height: 40px;
  width: 40px;
}

#mainNav {
  z-index: 2;
}
</style>