import { Store as VuexStore, CommitOptions, DispatchOptions, Module } from 'vuex'

import { RootState } from '@/store';

import { State, state } from './state';
import { Getters, getters } from './getters';
import { Actions, actions } from './actions';
import { Mutations, mutations } from './mutations';

export type { State };

export type CompStore<S = State> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'>
  & {
    commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
      key: K,
      payload: P,
      options?: CommitOptions
    ): ReturnType<Mutations[K]>;
  } & {
    dispatch<K extends keyof Actions>(
      key: K,
      payload: Parameters<Actions[K]>[1],
      options?: DispatchOptions
    ) : ReturnType<Actions[K]>;
  } & {
    getters: {
      [K in keyof Getters]: ReturnType<Getters[K]>
    };
  };

  export const store: Module<State, RootState> = {
    state,
    getters,
    mutations,
    actions
  }