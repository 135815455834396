import { createApp } from 'vue';
import App from './App.vue';

import router from './router';
import { store } from './store';
import { registerSW } from 'virtual:pwa-register';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faSearch,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBoxArchive,
  faFolderOpen,
  faFolder,
  faFile,
  faFileExcel,
  faFileWord,
  faFilePdf,
  faFilePowerpoint,
  faFileImage,
  faFileCsv,
  faChevronLeft,
  faChevronRight,
  faArrowDownLong,
  faArrowUpLong,
  faChevronDown,
  faCheck,
  faFileDownload,
  faMinus,
  faPlus,
  faPrint,
  faEnvelope,
  faEllipsis,
  faTrash,
  faChevronUp,
  faPen,
  faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';

import { faWindows } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faArrowDownLong,
  faArrowUpLong,
  faArrowRightFromBracket,
  faArrowRightToBracket,

  faBars,
  faBoxArchive,

  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,

  faEnvelope,
  faEllipsis,
  faEllipsisVertical,

  faFileDownload,
  faFolderOpen,
  faFolder,
  faFile,
  faFileExcel,
  faFileWord,
  faFilePdf,
  faFilePowerpoint,
  faFileImage,
  faFileCsv,

  faMinus,

  faPen,
  faPlus,
  faPrint,

  faSearch,

  faTrash,

  faWindows
);

registerSW({ immediate: true });

const app = createApp(App);

app.use(store);
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');

import 'bootstrap';
