import axios, { AxiosResponse } from 'axios';
import { setupInterceptorsTo } from './ArchivApiInterceptors';
import { LoginRequest, LogoutRequest, RefreshLoginRequest } from '@/models/AuthModels';
import { GetOrdnerRequest, CRDOrdnerRequest, CreateOrdnerPfadRequest } from '@/models/OrdnerModels';
import { GetDocsRequest, CRDDocRequest, CreateDocRequest } from '@/models/DocModels';
import { GetCompsRequest, DownloadCompRequest, CRDCompRequest, UploadCompFileRequest, CreateCompRequest } from '@/models/CompModels';

/* DEV */
// import config from '../../appsettings.dev.json';

/* PROD */
const appsettings = await fetch('/appsettings.json');
const config = await appsettings.json();

const BASE_URL = config.archivapi.apiUrl;
const API_KEY = config.archivapi.apiKey;

const apiClient = setupInterceptorsTo(
  axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ApiKey: API_KEY,
    },
  })
);

export default {
  //#region AUTH
  login(data: LoginRequest): Promise<AxiosResponse> {
    return apiClient.post('/login', data);
  },

  loginAzure(idToken: String): Promise<AxiosResponse> {
    const config = {
      headers: {
        idtoken: idToken.toString(),
      },
    };

    return apiClient.get('/login/azure', config);
  },

  validateOTPLogin(data: LoginRequest): Promise<AxiosResponse> {
    return apiClient.post('/login/otp', data);
  },

  refreshLogin(data: RefreshLoginRequest) {
    return apiClient.post('/login/refresh', data);
  },

  getAzureLoginConfig(): Promise<AxiosResponse> {
    return apiClient.get('/login/azureconfig');
  },

  logout(data: LogoutRequest): Promise<AxiosResponse> {
    return apiClient.post('/logout', data);
  },
  //#endregion

  //#region SESSION
  getSessions(): Promise<AxiosResponse> {
    return apiClient.get('/archiv4session');
  },
  //#endregion

  //#region  ARCHIV
  getArchivesBySessionid(): Promise<AxiosResponse> {
    return apiClient.get('/archiv4archive/sessionid');
  },
  //#endregion

  //#region  ORDNER
  getOrdner(data: GetOrdnerRequest): Promise<AxiosResponse> {
    let requestUrl = `archiv/${data.archivid}/ordner`;
    if (data.ownerid != '') {
      requestUrl += `?ownerid=${data.ownerid}`;
    }

    return apiClient.get(requestUrl);
  },

  createOrdner(data: CRDOrdnerRequest): Promise<AxiosResponse> {
    return apiClient.post(`archiv/${data.archivid}/ordner`, data.ordner);
  },

  createOrdnerPfad(data: CreateOrdnerPfadRequest): Promise<AxiosResponse> {
    let requestUrl = `archiv/${data.archivid}/ordner/pfad`;
    if (data.ownerid != null && data.ownerid != '') {
      requestUrl += `?ownerid=${encodeURIComponent(data.ownerid)}`;
    }

    return apiClient.post(requestUrl, data.ordnerPfad);
  },

  updateOrdner(data: CRDOrdnerRequest): Promise<AxiosResponse> {
    return apiClient.put(`archiv/${data.archivid}/ordner`, data.ordner);
  },

  deleteOrdner(data: CRDOrdnerRequest): Promise<AxiosResponse> {
    return apiClient.delete(`archiv/${data.archivid}/ordner/${encodeURIComponent(data.ordner.ordid)}`);
  },
  //#endregion

  //#region  DOCS
  getDocs(data: GetDocsRequest): Promise<AxiosResponse> {
    return apiClient.get(`archiv/${data.archivid}/ordner/${encodeURIComponent(data.ordid)}/docs`);
  },

  createDoc(data: CreateDocRequest): Promise<AxiosResponse> {
    return apiClient.post(`archiv/${data.archivid}/doc/file`, data.data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important: Use multipart/form-data content type
      },
    });
  },

  updateDoc(data: CRDDocRequest): Promise<AxiosResponse> {
    return apiClient.put(`archiv/${data.archivid}/doc`, data.doc);
  },

  deleteDoc(data: CRDDocRequest): Promise<AxiosResponse> {
    return apiClient.delete(`archiv/${data.archivid}/doc/${encodeURIComponent(data.doc.docid)}`);
  },
  //#endregion

  //#region  COMPS
  getComps(data: GetCompsRequest): Promise<AxiosResponse> {
    return apiClient.get(`archiv/${data.archivid}/doc/${encodeURIComponent(data.docid.trim())}/comps`);
  },

  createComp(data: CreateCompRequest): Promise<AxiosResponse> {
    return apiClient.post(`archiv/${data.archivid}/comp/file`, data.data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important: Use multipart/form-data content type
      },
    });
  },

  uploadCompFile(data: UploadCompFileRequest): Promise<AxiosResponse> {
    return apiClient.post(`archiv/${data.archivid}/comp/file/upload`, data.data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important: Use multipart/form-data content type
      },
    });
  },

  updateComp(data: CRDCompRequest): Promise<AxiosResponse> {
    return apiClient.put(`archiv/${data.archivid}/comp`, data.comp);
  },

  deleteComp(data: CRDCompRequest): Promise<AxiosResponse> {
    return apiClient.delete(`archiv/${data.archivid}/comp/${encodeURIComponent(data.comp.compid)}`);
  },

  getCompFile(data: DownloadCompRequest): Promise<AxiosResponse> {
    return apiClient.get(
      `/archiv4comp/getfile?archivmandantid=${data.archivmandantid}&archivname=${data.archivname}&docguid=${data.docguid}&compguid=${data.compguid}`,
      { responseType: 'blob' }
    ); // Important to specify responseType as blob
  },
  //#endregion
};
