<template>
  <div
    class="modal fade"
    :id="'deleteCompModal' + comp.id"
    tabindex="-1"
    :aria-labelledby="'deleteCompModal' + comp.id"
    aria-hidden="true"
    ref="deleteCompModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-danger text-white py-1">
          <strong class="modal-title my-auto" :id="'deleteCompModalLabel' + comp.id">Dokument löschen </strong>

          <button type="button" class="btn-close btn-sm" @click="closeDeleteModal()"></button>
        </div>

        <div class="modal-body" :id="'deleteCompModalBody' + comp.id">
          <div>
            <p>
              Die Componente <strong class="text-danger">{{ comp.titel }}</strong> sowie die dazugehörige Datei werden gelöscht
            </p>
          </div>

          <div v-if="deleteCompErrorMsg != null && deleteCompErrorMsg != '' && showDeleteErrorMsg" class="alert alert-danger">
            <!-- {{ updateErrorMsg }} -->
            <!-- <br> -->
            Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm" @click="closeDeleteModal()">Abbrechen</button>
          <button type="button" class="btn btn-danger btn-sm" @click="deleteOrdner()">Datei löschen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { ref, computed, PropType } from 'vue';
  import { useStore } from '@/store';
  import { Modal } from 'bootstrap';

  import { ActionTypes as OrdnerActionTypes } from '@/store/modules/Ordner/actions';

  import { Comp, CRDCompRequest } from '@/models/CompModels';
  //#endregion

  const props = defineProps({
    comp: {
      type: Object as PropType<Comp>,
      required: true,
    },
    archivid: {
      type: Number as PropType<number>,
      required: true,
    },
  });

  const emit = defineEmits({
    thisLoading(loading: boolean) {
      return loading;
    },
  });

  const store = useStore();

  const deleteCompErrorMsg = computed(() => store.getters.status.compErrorMsg);
  const showDeleteErrorMsg = ref(false);

  const deleteCompModalRef = ref();
  function closeDeleteModal() {
    Modal.getInstance(deleteCompModalRef.value)?.hide();
  }

  async function showDeleteModal() {
    showDeleteErrorMsg.value = false;

    if (deleteCompModalRef.value) {
      new Modal(deleteCompModalRef.value).show();
    }
  }

  function deleteOrdner() {
    emit('thisLoading', true);

    const request = new CRDCompRequest(props.comp, props.archivid);
    closeDeleteModal();
    store
      .dispatch(OrdnerActionTypes.DeleteComp, request)
      .then(() => {
        if (deleteCompErrorMsg != null && deleteCompErrorMsg.value != '') {
          showDeleteModal();
          showDeleteErrorMsg.value = true;
        }
      })
      .finally(() => {
        emit('thisLoading', false);
      });
  }

  defineExpose({
    showDeleteModal,
  });
</script>
