import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from './mutations';

import { Status } from '@/models/CommonModels';

import { RootState } from '@/store';
import { State } from './state';

export enum ActionTypes {
  SetStatus = 'SETSTATUS',
  ToggleSidebar = 'TOGGLESIDEBAR',

  ShowMenu = 'SHOWMENU',
  CloseMenu = 'CLOSEMENU',
}

type ActionArguments = Omit<ActionContext<State, RootState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.SetStatus](context: ActionArguments, status: Status): void;
  [ActionTypes.ToggleSidebar](context: ActionArguments): void;
  [ActionTypes.ShowMenu](context: ActionArguments, menuId: string): void;
  [ActionTypes.CloseMenu](context: ActionArguments): void;
};

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.SetStatus]({ commit }: ActionArguments, status: Status) {
    commit(MutationTypes.SetStatus, status);
  },

  [ActionTypes.ToggleSidebar]({ commit, state }: ActionArguments) {
    const status = state.status;
    status.sidebarCollapsed = !status.sidebarCollapsed;
    commit(MutationTypes.SetStatus, status);
  },

  [ActionTypes.ShowMenu]({ commit }: ActionArguments, menuid: string) {
    commit(MutationTypes.SetActiveMenu, menuid);
  },

  [ActionTypes.CloseMenu]({ commit }: ActionArguments) {
    commit(MutationTypes.SetActiveMenu, null);
  },
};
