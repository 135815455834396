<template>
  <div v-if="!archiv"></div>
  <!-- <h1 class="mb-4 fs-4">{{ name }}</h1> -->
  <div
    v-else
    class="drag-drop-area"
    :class="{ 'drag-over': isDragOver }"
    @contextmenu.prevent="showArchivContextMenu"
    @dragover.prevent="handleDragOver"
    @dragleave="handleDragLeave"
    @drop.prevent="handleDrop"
  >
    <div class="mb-4 pb-3 border-bottom">
      <div class="dropdown">
        <button class="btn btn-outline-primary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <font-awesome-icon icon="plus" /> Hinzufügen
        </button>
        <ul class="dropdown-menu">
          <li>
            <button class="dropdown-item" @click="showNewFolderModal()" :disabled="!rechte.ordnerCreate">
              <div class="row">
                <div class="col-3">
                  <font-awesome-icon icon="folder" class="fa-sm me-2" />
                </div>
                <div class="col">Ordner</div>
              </div>
            </button>
          </li>

          <li><hr class="dropdown-divider" /></li>

          <li>
            <button class="dropdown-item" @click="showNewDocModal()" :disabled="currentOrdner.length == 0 || !rechte.dokumentCreate">
              <div class="row">
                <div class="col-3">
                  <font-awesome-icon icon="file" class="fa-sm me-2" />
                </div>
                <div class="col">Dokument</div>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="mb-4">
      <span v-if="currentOrdner.length == 0" class="fw-bold">{{ name }} </span>
      <span v-if="currentOrdner.length > 0">
        <span @click="backToRoot()" class="breadcrum-item">{{ name }} </span>
        <span v-for="(ord, idx) in currentOrdner" :key="ord.id">
          <span v-if="idx == currentOrdner.length - 1">
            > <span class="fw-bold">{{ ord.titel }}</span></span
          >
          <span v-else @click="backToOrdner(ord)" class="breadcrum-item"> > {{ ord.titel }}</span>
        </span>
      </span>
    </div>

    <div class="bg-white shadow">
      <div class="row border-bottom m-0">
        <div class="col-8 py-2 table-col">
          <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <input id="select-all-elements-input" class="form-check-input me-4" type="checkbox" value="" />
            <font-awesome-icon icon="file" class="text-secondary me-4" />
            <span class="fw-bold me-1">Titel</span>
            <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'titelasc'" class="text-secondary table-sort-icon" />
            <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'titeldesc'" class="text-secondary table-sort-icon" />

            <ul class="dropdown-menu m-0 ms-5 p-0 rounded-0">
              <li class="dropdown-item select-sort" @click="sortBy('titelasc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'titelasc'" />
                  </div>
                  <div class="col">A bis Z</div>
                </div>
              </li>
              <li class="dropdown-item select-sort" @click="sortBy('titeldesc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'titeldesc'" />
                  </div>
                  <div class="col">Z bis A</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-2 py-2 table-col">
          <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="fw-bold me-1">Barcode</span>
            <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'barcodeasc'" class="text-secondary table-sort-icon" />
            <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'barcodedesc'" class="text-secondary table-sort-icon" />

            <ul class="dropdown-menu m-0 p-0 rounded-0">
              <li class="dropdown-item select-sort" @click="sortBy('barcodeasc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'barcodeasc'" />
                  </div>
                  <div class="col">A bis Z</div>
                </div>
              </li>
              <li class="dropdown-item select-sort" @click="sortBy('barcodedesc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'barcodedesc'" />
                  </div>
                  <div class="col">Z bis A</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-2 py-2 text-end table-col">
          <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="fw-bold me-1">Datum</span>
            <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'datumasc'" class="text-secondary table-sort-icon" />
            <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'datumdesc'" class="text-secondary table-sort-icon" />

            <ul class="dropdown-menu m-0 p-0 rounded-0">
              <li class="dropdown-item select-sort" @click="sortBy('datumasc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'datumasc'" />
                  </div>
                  <div class="col">Älteste oben</div>
                </div>
              </li>
              <li class="dropdown-item select-sort" @click="sortBy('datumdesc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'datumdesc'" />
                  </div>
                  <div class="col">Neueste oben</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <LoadingSpinner v-if="ordnerLoading || docLoading" text="...Ordner und Dokumente werden geladen..." class="pb-5" />

      <div v-if="!ordnerLoading && !docLoading && currentOrdner.length == 0">
        <div v-for="(ord, idx) in sortedOrdner" :key="ord.id">
          <div :class="{ 'border-bottom': idx != ordner.length - 1 }">
            <OrdnerComp :ord="ord" :archivid="parseInt(id)" />
          </div>
        </div>
      </div>

      <div v-else-if="!ordnerLoading && !docLoading && currentOrdner.length > 0">
        <div v-if="displayedOrdner.length == 0 && currentOrdner[currentOrdner.length - 1].docs.length == 0" class="px-5 py-3">
          <div class="alert alert-warning">Es wurden keine Ordner und keine Dokumente gefunden.</div>
        </div>
        <!-- <div v-for="ord, idx in currentOrdner[currentOrdner.length - 1].ordner" :key="ord.id"> -->
        <div v-if="rechte.ordnerRead">
          <div v-for="(ord, idx) in displayedOrdner" :key="ord.id">
            <div
              :class="{
                'border-bottom': idx != currentOrdner[currentOrdner.length - 1].ordner.length - 1 || currentOrdner[currentOrdner.length - 1].docs.length > 0,
              }"
            >
              <OrdnerComp :ord="ord" :archivid="parseInt(id)" />
            </div>
          </div>
        </div>

        <div
          v-if="
            currentOrdner[currentOrdner.length - 1].docs != null &&
            currentOrdner[currentOrdner.length - 1].docs != undefined &&
            currentOrdner[currentOrdner.length - 1].docs.length > 0 &&
            rechte.dokumentRead
          "
        >
          <!-- <div v-for="doc, idx in currentOrdner[currentOrdner.length - 1].docs.sort((a, b) => a.titel.toLowerCase() < b.titel.toLowerCase() ? -1 : 1)" :key="doc.id"> -->
          <div v-for="(doc, idx) in displayDocs" :key="doc.id">
            <div :class="{ 'border-bottom': idx != currentOrdner[currentOrdner.length - 1].docs.length - 1 }">
              <DokumentComp :doc="doc" :archivid="parseInt(id)" :archivname="archiv?.archivname" :mandantid="archiv?.mandantid" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- New Folder Modal -->
    <div class="modal fade" id="newFolderModal" tabindex="-1" aria-labelledby="newFolderModal" aria-hidden="true" ref="newFolderModalRef">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header py-1">
            <span class="modal-title my-auto" id="newFolderModalLabel">Ordner erstellen</span>
            <button type="button" class="btn-close" aria-label="Close" @click="closeNewFolderModal()"></button>
          </div>
          <div class="modal-body">
            <LoadingSpinner v-if="createOrdnerLoading" text="...Ordner wird erstellt..." />

            <div v-else class="mb-3">
              <label for="newFolderTitelInput" class="form-label">Titel</label>
              <input type="text" class="form-control" id="newFolderTitelInput" v-model="newFolderTitel" />
            </div>
            <p class="text-danger" v-show="newFolderTitelError != ''">{{ newFolderTitelError }}</p>
          </div>
          <div class="modal-footer py-1">
            <button type="button" class="btn btn-outline-primary btn-sm" @click="createNewOrdner()">Erstellen</button>
          </div>
        </div>
      </div>
    </div>

    <AddDokumentModal :archiv="archiv" ref="createDocModalRef" />
    <ContextMenu ref="contextMenuRef" :items="contextMenuItems" menuId="archiv-context-menu" />

    <UploadNotificationManager ref="uploadNotificationManagerRef" />
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { ref, computed, onMounted, onBeforeUpdate } from 'vue';
  import { useStore } from '@/store';
  import { useRoute } from 'vue-router';
  import { archivHelperfunction } from '@/utils/ArchivFunctions';
  import { Modal } from 'bootstrap';

  import { ActionTypes as OrdnerActionTypes } from '@/store/modules/Ordner/actions';
  import { ActionTypes as ArchivActionTypes } from '@/store/modules/Archiv/actions';

  import { CRDOrdnerRequest, CreateOrdnerPfadRequest, GetOrdnerRequest, Ordner } from '@/models/OrdnerModels';
  import { CreateDocRequest, CreateDocData, Doc } from '@/models/DocModels';

  import OrdnerComp from '@/components/Archiv/OrdnerComp.vue';
  import DokumentComp from '@/components/Archiv/Dokumente/DokumentComp.vue';
  import AddDokumentModal from '@/components/Archiv/Dokumente/AddDokumentModal.vue';
  import ContextMenu from '@/components/ContextMenu.vue';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import UploadNotificationManager from '@/components/Notifications/UploadNotificationManager.vue';
  import { ArchivRechte } from '@/models/ArchivModels';
  //#endregion

  const store = useStore();
  const route = useRoute();

  const name = ref('');
  const id = ref('');

  const { getArchivrechte } = archivHelperfunction();

  const ordnerLoading = computed(() => store.getters.status.ordnerLoading);
  const docLoading = computed(() => store.getters.status.docLoading);
  const docErrorMsg = computed(() => store.getters.status.docErrorMsg);
  // const archivErrorMsg = computed(() => store.getters.status.ordnerErrorMsg);

  const sortByValue = ref('titelasc');
  const filter = ref('');

  const sortBy = (param: string) => {
    sortByValue.value = param;
    localStorage.setItem('archivSortBy', param);
  };

  //#region Archiv
  const archive = computed(() => store.getters.archive);
  const archiv = computed(() => archive.value.find((obj) => obj.id == parseInt(id.value)));
  const rechte = computed(() => {
    if (archiv.value) {
      return getArchivrechte(archiv.value.archivrechte);
    } else {
      return new ArchivRechte();
    }
  });

  async function getArchive(): Promise<void> {
    await store.dispatch(ArchivActionTypes.GetArchive, undefined);
    return new Promise((res, _rej) => {
      res();
    });
  }
  //#endregion

  //#region Ordner
  function backToOrdner(ordner: Ordner) {
    store.dispatch(OrdnerActionTypes.SetCurrentOrdner, ordner);
  }

  function backToRoot() {
    store.dispatch(OrdnerActionTypes.ClearCurrentOrdner, undefined);
  }

  const ordner = computed(() => store.getters.ordner);
  const sortedOrdner = computed(() => {
    return ordner.value
      .filter((a) => a.titel.trim().toLowerCase().includes(filter.value.trim()))
      .sort((a, b) => {
        if (sortByValue.value == 'titeldesc' || sortByValue.value == 'titelasc') {
          var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase());
          if (sortByValue.value == 'titeldesc') {
            titleComparison = -1 * titleComparison;
          }

          if (titleComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString());
            return dateComparison;
          }

          return titleComparison;
        } else if (sortByValue.value == 'barcodedesc' || sortByValue.value == 'barcodeasc') {
          if (!a.barcode || !b.barcode) {
            return 0;
          }

          var barcodeComparison = a.barcode.trim().toLowerCase().localeCompare(b.barcode.trim().toLowerCase());
          if (sortByValue.value == 'barcodedesc') {
            barcodeComparison *= -1;
          }

          if (barcodeComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString());
            return dateComparison;
          }

          return barcodeComparison;
        } else if (sortByValue.value == 'datumdesc' || sortByValue.value == 'datumasc') {
          if (sortByValue.value == 'datumasc' && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? -1 : 1;
          } else if (sortByValue.value == 'datumdesc' && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? 1 : -1;
          } else {
            return 0;
          }

          // if (sortByValue.value == "datumasc") {
          //   return a.mdate < b.mdate ? -1 : 1
          // }
          // else {
          //   return a.mdate < b.mdate ? 1 : -1
          // }
        }

        return 0;
      });
  });

  const currentOrdner = computed(() => store.getters.currentOrdner);

  const displayedOrdner = computed(() => {
    return currentOrdner.value[currentOrdner.value.length - 1].ordner
      .filter((a) => a.titel.trim().toLowerCase().includes(filter.value.trim()))
      .sort((a, b) => {
        if (sortByValue.value == 'titeldesc' || sortByValue.value == 'titelasc') {
          var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase());
          if (sortByValue.value == 'titeldesc') {
            titleComparison = -1 * titleComparison;
          }

          if (titleComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString());
            return dateComparison;
          }

          return titleComparison;
        } else if (sortByValue.value == 'barcodedesc' || sortByValue.value == 'barcodeasc') {
          if (!a.barcode || !b.barcode) {
            return 0;
          }

          var barcodeComparison = a.barcode.trim().toLowerCase().localeCompare(b.barcode.trim().toLowerCase());
          if (sortByValue.value == 'barcodedesc') {
            barcodeComparison *= -1;
          }

          if (barcodeComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString());
            return dateComparison;
          }

          return barcodeComparison;
        } else if (sortByValue.value == 'datumdesc' || sortByValue.value == 'datumasc') {
          if (sortByValue.value == 'datumasc' && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? -1 : 1;
          } else if (sortByValue.value == 'datumdesc' && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? 1 : -1;
          } else {
            return 0;
          }

          // if (sortByValue.value == "datumasc") {
          //   return a.mdate < b.mdate ? -1 : 1
          // }
          // else {
          //   return a.mdate < b.mdate ? 1 : -1
          // }
        }

        return 0;
      });
  });

  function getArchivOrdner() {
    store.dispatch(ArchivActionTypes.SetSelectedArchiv, archiv.value);
    store.dispatch(OrdnerActionTypes.ClearCurrentOrdner, undefined);
    store.dispatch(OrdnerActionTypes.ClearOrdner, undefined);
    store.dispatch(OrdnerActionTypes.GetOrdner, new GetOrdnerRequest(parseInt(id.value), ''));
  }
  //#endregion

  //#region New Folder Modal
  const createOrdnerLoading = computed(() => store.getters.status.createOrdnerLoading);
  const createOrdnerErrorMsg = computed(() => store.getters.status.createOrdnerErrorMsg);
  const newFolderTitel = ref('Neuer Ordner');
  const newFolderTitelError = ref('');
  const newFolderModalRef = ref();

  function closeNewFolderModal() {
    newFolderTitel.value = 'Neuer Ordner';
    newFolderTitelError.value = '';
    Modal.getInstance(newFolderModalRef.value)?.hide();
  }

  function showNewFolderModal() {
    if (newFolderModalRef.value) {
      new Modal(newFolderModalRef.value).show();
    }
  }

  function createNewOrdner() {
    newFolderTitelError.value = '';

    if (archiv.value != null) {
      if (newFolderTitel.value.trim() == '') {
        newFolderTitelError.value = 'Der Titel darf nicht leer sein.';
      } else {
        if (newFolderTitel.value.includes('\\')) {
          createOrdnerPfad(newFolderTitel.value.trim());
        } else {
          createOrdner(newFolderTitel.value.trim());
        }
      }
    }
  }

  async function createOrdnerPfad(pfad: string) {
    if (archiv.value != null) {
      let ownerid: string | null = null;

      if (currentOrdner.value.length > 0) {
        ownerid = currentOrdner.value[currentOrdner.value.length - 1].ordid;
      }

      const request = new CreateOrdnerPfadRequest(archiv.value.id, pfad, ownerid);
      await store.dispatch(OrdnerActionTypes.CreateOrdnerPfad, request);

      if (createOrdnerErrorMsg.value != '') {
        newFolderTitelError.value = 'Es ist ein Fehler aufgetreten';
      } else {
        closeNewFolderModal();
      }
    }
  }

  async function createOrdner(titel: string) {
    if (archiv.value != null) {
      let ownerid = '';

      if (currentOrdner.value.length > 0) {
        ownerid = currentOrdner.value[currentOrdner.value.length - 1].ordid;
      }

      const newOrdner = new Ordner();
      newOrdner.titel = titel;
      newOrdner.ownerid = ownerid;

      var request = new CRDOrdnerRequest(newOrdner, archiv.value.id);
      await store.dispatch(OrdnerActionTypes.CreateOrdner, request);

      if (createOrdnerErrorMsg.value != '') {
        newFolderTitelError.value = 'Es ist ein Fehler aufgetreten';
      } else {
        closeNewFolderModal();
      }
    }
  }
  //#endregion

  //#region New Dokument Modal
  const createDocModalRef = ref();

  function showNewDocModal() {
    if (createDocModalRef.value) {
      createDocModalRef.value.showModal();
    }
  }
  //#endregion

  //#region Dokumente
  const displayDocs = computed(() => {
    return currentOrdner.value[currentOrdner.value.length - 1].docs
      .filter((a) => a.titel.trim().toLowerCase().includes(filter.value.trim()))
      .sort((a, b) => {
        if (sortByValue.value == 'titeldesc' || sortByValue.value == 'titelasc') {
          var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase());
          if (sortByValue.value == 'titeldesc') {
            titleComparison = -1 * titleComparison;
          }

          if (titleComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString());
            return dateComparison;
          }

          return titleComparison;
        } else if (sortByValue.value == 'barcodedesc' || sortByValue.value == 'barcodeasc') {
          if (!a.barcode || !b.barcode) {
            return 0;
          }

          var barcodeComparison = a.barcode.trim().toLowerCase().localeCompare(b.barcode.trim().toLowerCase());
          if (sortByValue.value == 'barcodedesc') {
            barcodeComparison *= -1;
          }

          if (barcodeComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString());
            return dateComparison;
          }

          return barcodeComparison;
        } else if (sortByValue.value == 'datumdesc' || sortByValue.value == 'datumasc') {
          if (sortByValue.value == 'datumasc' && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? -1 : 1;
          } else if (sortByValue.value == 'datumdesc' && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? 1 : -1;
          } else {
            return 0;
          }

          // if (sortByValue.value == "datumasc") {
          //   return a.mdate < b.mdate ? -1 : 1
          // }
          // else {
          //   return a.mdate < b.mdate ? 1 : -1
          // }
        }

        return 0;
      });
  });

  //#endregion

  //#region UploadNotificationManager
  const uploadNotificationManagerRef = ref();

  function setUploadNotification(fileName: string, loading: boolean = false, background: string = 'text-bg-primary'): string | undefined {
    if (uploadNotificationManagerRef.value) {
      return uploadNotificationManagerRef.value.addNotification(fileName, loading, background);
    } else {
      return undefined;
    }
  }
  //#endregion

  //#region Drag and Drop
  const MAX_FILE_SIZE = 50 * 1024 * 1024;
  const isDragOver = ref(false);

  const handleDragOver = () => {
    if (currentOrdner.value.length > 0) {
      isDragOver.value = true;
    }
  };

  const handleDragLeave = () => {
    isDragOver.value = false;
  };

  const handleDrop = (event: DragEvent) => {
    if (!rechte.value.dokumentCreate) {
      setUploadNotification('Sie haben keine Berechtigung, Dokumente hochzuladen.', false, 'text-bg-danger');
      return;
    }

    if (currentOrdner.value.length > 0) {
      isDragOver.value = false;
      // console.log(event);
      // console.log(event.dataTransfer);
      if (event.dataTransfer?.files) {
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          const file = event.dataTransfer.files[i];
          if (file.type !== '') {
            createDokument(file);
          } else {
            console.log('Folder detected:', file.name);
          }
        }
        // addFiles(event.dataTransfer.files);
      }
    } else {
      setUploadNotification('Bitte wählen Sie zunächst einen Ordner aus.', false, 'text-bg-danger');
    }
  };

  async function createDokument(file: File) {
    if (archiv.value == null || currentOrdner.value.length == 0) {
      setUploadNotification('Es ist kein Ordner ausgewählt', false, 'text-bg-danger');
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setUploadNotification(file.name + ' ist zu groß. Die maximale Dateigröße ist 25MB.', false, 'text-bg-danger');
      return;
    }

    const messageId = setUploadNotification(file.name + ' wird hochgeladen', true);

    const doc = new Doc();
    doc.ordid = currentOrdner.value[currentOrdner.value.length - 1].ordid;

    const data: CreateDocData = {
      doc: JSON.parse(JSON.stringify(doc)),
      file: file,
    };

    const request: CreateDocRequest = {
      data: data,
      archivid: archiv.value.id,
    };

    return store.dispatch(OrdnerActionTypes.CreateDoc, request).then(() => {
      if (messageId) {
        uploadNotificationManagerRef.value?.removeNotification(messageId);
      }

      if (docErrorMsg.value != '') {
        setUploadNotification(file.name + ' konnte nicht hochgeladen werden', false, 'text-bg-danger');
      }
    });
  }
  //#endregion

  //#region ContextMenu
  const contextMenuRef = ref();
  function showArchivContextMenu(event: MouseEvent) {
    event.stopPropagation();

    if (contextMenuRef.value) {
      contextMenuRef.value.showMenu(event.clientX, event.clientY);
    }
  }

  const contextMenuItems = ref<Array<any>>([]);

  function setContextMenuItems() {
    // Clear items
    contextMenuItems.value = [];

    // Create Ordner
    contextMenuItems.value.push({ label: 'Neuer Ordner', action: () => showNewFolderModal(), disabled: !rechte.value.ordnerCreate });

    // Create Dokument
    contextMenuItems.value.push({
      label: 'Neues Dokument',
      action: () => showNewDocModal(),
      disabled: currentOrdner.value.length == 0 || !rechte.value.dokumentCreate,
    });
    // console.log(contextMenuItems.value);
  }
  //#endregion

  onMounted(async () => {
    if (archive.value.length == 0) {
      await getArchive();
    }

    if (localStorage.getItem('archivSortBy') && localStorage.getItem('archivSortBy') != '') {
      sortByValue.value = localStorage.getItem('archivSortBy') as string;
    }

    setContextMenuItems();

    if (name.value != (route.params.name as string) || id.value != route.query.id?.toString()) {
      name.value = route.params['name'] as string;
      if (route.query.id) {
        id.value = route.query.id.toString();
      }

      getArchivOrdner();
    }
  });

  onBeforeUpdate(async () => {
    if (archive.value.length == 0) {
      await getArchive();
    }

    if (name.value != (route.params.name as string) || id.value != route.query.id?.toString()) {
      name.value = route.params['name'] as string;
      if (route.query.id) {
        id.value = route.query.id.toString();
      }

      getArchivOrdner();
    }

    setContextMenuItems();
  });
</script>

<style lang="scss" scoped>
  .breadcrum-item {
    cursor: pointer;
  }

  .table-col:hover {
    background: rgb(216, 217, 222);
  }

  .table-sort-icon {
    font-size: 0.7em;
  }

  .select-sort:hover {
    cursor: pointer;
  }

  .drag-drop-area {
    min-height: 90vh;
  }

  .drag-drop-area.drag-over {
    background-color: #f8f9fa;
    border-color: #007bff;
  }
</style>
