import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";

export type Getters = {
}

export const getters: GetterTree<State, RootState> & Getters = {
}
