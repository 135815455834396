import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";

import { Archiv, Mandant } from "@/models/ArchivModels"

export type Getters = {
  archive(state: State): Array<Archiv>
  selectedArchiv(state: State): Archiv | undefined
  mandanten(state: State): Array<Mandant>
  selectedMandant(state: State): Mandant
}

export const getters: GetterTree<State, RootState> & Getters = {
  archive(state) {
    return state.archive;
  },

  selectedArchiv(state) {
    return state.selectedArchiv;
  },
  
  mandanten(state) {
    return state.mandanten;
  },

  selectedMandant(state) {
    return state.selectedMandant;
  }
}
