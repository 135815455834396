import { GetterTree } from 'vuex';
import { State } from './state';
import { Status } from '@/models/CommonModels';
import { RootState } from '@/store';

export type Getters = {
  status(state: State): Status;
  activeMenu(state: State): string | null;
};

export const getters: GetterTree<State, RootState> & Getters = {
  status(state: State): Status {
    return state.status;
  },

  activeMenu(state: State): string | null {
    return state.activeMenu;
  },
};
