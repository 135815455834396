import { MutationTree } from "vuex";
import { State } from "./state";

import { Comp } from "@/models/CompModels";

export enum MutationTypes {
  ComponentenSuccess = "COMPONENTENSUCCESS",
}


export type Mutations = {
  [MutationTypes.ComponentenSuccess](state: State, data: Comp): void
}

export const mutations: MutationTree<State> & Mutations = {

  [MutationTypes.ComponentenSuccess](state, data) {
    state.componente = data;
    // for (const dokumentart of state.dokumentarten) {
    //   for (const dokument of dokumentart.dokumente) {
    //     if (dokument.docid == data[0].docid) {
    //       dokument.componenten = data;
    //     }
    //   }
    // }
  },
}