import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from './mutations';
import ArchivApiService from '@/service/ArchivApiService';
import { ActionTypes as CommonActionTypes } from '@/store/modules/Common/actions';

import { RootState } from '@/store';

import { State } from './state';
import { CRDOrdnerRequest, CreateOrdnerPfadRequest, GetOrdnerRequest, Ordner } from '@/models/OrdnerModels';
import { CRDDocRequest, CreateDocRequest, Doc, GetDocsRequest } from '@/models/DocModels';
import { Comp, GetCompsRequest, CRDCompRequest, UploadCompFileRequest, CreateCompRequest } from '@/models/CompModels';

export enum ActionTypes {
  //#region Ordner
  GetOrdner = 'GETORDNER',
  CreateOrdner = 'CREATEORDNER',
  CreateOrdnerPfad = 'CREATEORDNERPFAD',
  UpdateOrdner = 'UPDATEORDNER',
  DeleteOrdner = 'DELETEORDNER',

  GetOrdnerFromSidebar = 'GETORDNERSIDEBAR',
  SetCurrentOrdner = 'SETCURRENTORDNER',
  SetCurrentOrdnerFromSidebar = 'SETCURRENTORDNERFROMSIDEBAR',
  ClearOrdner = 'CLEARORDNER',
  ClearCurrentOrdner = 'CLEARCURRENTORDNER',
  //#endregion

  //#region Docs
  GetDocs = 'GETDOCS',
  CreateDoc = 'CREATEDOC',
  UpdateDoc = 'UPDATEDOC',
  DeleteDoc = 'DELETEDOC',
  //endregion

  //#region Comps
  GetComps = 'GETCOMPS',
  CreateComp = 'CREATECOMP',
  UploadCompFile = 'UPLOADCOMPFILE',
  UpdateComp = 'UPDATECOMP',
  DeleteComp = 'DELETECOMP',
  //#endregion
}

type ActionArguments = Omit<ActionContext<State, RootState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  //#region Ordner
  [ActionTypes.GetOrdner](context: ActionArguments, ordnerRequest: GetOrdnerRequest): void;
  [ActionTypes.CreateOrdner](context: ActionArguments, data: CRDOrdnerRequest): Promise<void>;
  [ActionTypes.CreateOrdnerPfad](context: ActionArguments, data: CreateOrdnerPfadRequest): Promise<void>;
  [ActionTypes.UpdateOrdner](context: ActionArguments, data: CRDOrdnerRequest): Promise<void>;
  [ActionTypes.DeleteOrdner](context: ActionArguments, data: CRDOrdnerRequest): Promise<void>;

  [ActionTypes.GetOrdnerFromSidebar](context: ActionArguments, ordnerRequest: GetOrdnerRequest): Promise<void>;
  [ActionTypes.SetCurrentOrdner](context: ActionArguments, ordner: Ordner): void;
  [ActionTypes.SetCurrentOrdnerFromSidebar](context: ActionArguments, ordner: Ordner): void;
  [ActionTypes.ClearOrdner](context: ActionArguments): void;
  [ActionTypes.ClearCurrentOrdner](context: ActionArguments): void;
  //#endregion

  //#region Docs
  [ActionTypes.GetDocs](context: ActionArguments, data: GetDocsRequest): Promise<void>;
  [ActionTypes.CreateDoc](context: ActionArguments, data: CreateDocRequest): Promise<void>;
  [ActionTypes.UpdateDoc](context: ActionArguments, data: CRDDocRequest): Promise<void>;
  [ActionTypes.DeleteDoc](context: ActionArguments, data: CRDDocRequest): Promise<void>;
  //#endregion

  //#region Comps
  [ActionTypes.GetComps](context: ActionArguments, data: GetCompsRequest): void;
  [ActionTypes.CreateComp](context: ActionArguments, data: CreateCompRequest): Promise<void>;
  [ActionTypes.UploadCompFile](context: ActionArguments, data: UploadCompFileRequest): Promise<void>;
  [ActionTypes.UpdateComp](context: ActionArguments, data: CRDCompRequest): Promise<void>;
  [ActionTypes.DeleteComp](context: ActionArguments, data: CRDCompRequest): Promise<void>;
  //#endregion
};

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Ordner
  [ActionTypes.GetOrdner]({ commit, dispatch, rootGetters }: ActionArguments, ordnerRequest: GetOrdnerRequest) {
    const status = rootGetters.status;
    status.ordnerLoading = true;
    status.ordnerErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    ArchivApiService.getOrdner(ordnerRequest)
      .then((res) => {
        const ordner: Array<Ordner> = res.data;

        ordner.forEach((ord) => {
          ord.ordner = new Array<Ordner>();
          ord.docs = new Array<Doc>();
        });

        commit(MutationTypes.GetOrdnerSuccess, ordner);
      })
      .catch((error) => {
        status.ordnerErrorMsg = error.response.data.title;
      })
      .finally(() => {
        status.ordnerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.CreateOrdner]({ commit, dispatch, rootGetters }: ActionArguments, data: CRDOrdnerRequest) {
    const status = rootGetters.status;
    status.createOrdnerLoading = true;
    status.createOrdnerErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.createOrdner(data)
      .then((res) => {
        const ordner: Ordner = res.data;
        ordner.ordner = new Array<Ordner>();
        ordner.docs = new Array<Doc>();

        commit(MutationTypes.CreateOrdnerSuccess, ordner);
      })
      .catch((error) => {
        status.createOrdnerErrorMsg = error.response.toString();
      })
      .finally(() => {
        status.createOrdnerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.CreateOrdnerPfad]({ commit, dispatch, rootGetters }: ActionArguments, data: CreateOrdnerPfadRequest) {
    const status = rootGetters.status;
    status.createOrdnerLoading = true;
    status.createOrdnerErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.createOrdnerPfad(data)
      .then((res) => {
        const ordner: Ordner = res.data;
        ordner.ordner = new Array<Ordner>();
        ordner.docs = new Array<Doc>();

        commit(MutationTypes.CreateOrdnerSuccess, ordner);
      })
      .catch((error) => {
        status.createOrdnerErrorMsg = error.response.toString();
      })
      .finally(() => {
        status.createOrdnerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.UpdateOrdner]({ commit, dispatch, rootGetters }: ActionArguments, data: CRDOrdnerRequest) {
    const status = rootGetters.status;
    status.updateOrdnerLoading = true;
    status.updateOrdnerErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.updateOrdner(data)
      .then((res) => {
        const ordner: Ordner = res.data;
        ordner.ordner = new Array<Ordner>();
        ordner.docs = new Array<Doc>();

        commit(MutationTypes.UpdateOrdnerSuccess, ordner);
      })
      .catch((error) => {
        console.log(error);
        status.updateOrdnerErrorMsg = error.toString;
      })
      .finally(() => {
        status.updateOrdnerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.DeleteOrdner]({ commit, dispatch, rootGetters }: ActionArguments, data: CRDOrdnerRequest) {
    const status = rootGetters.status;
    status.deleteOrdnerLoading = true;
    status.deleteOrdnerErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.deleteOrdner(data)
      .then(() => {
        commit(MutationTypes.DeleteOrdnerSuccess, data.ordner.ordid);
      })
      .catch((error) => {
        console.log(error);
        status.deleteOrdnerErrorMsg = error.toString;
      })
      .finally(() => {
        status.deleteOrdnerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.GetOrdnerFromSidebar]({ commit, dispatch, rootGetters }: ActionArguments, ordnerRequest: GetOrdnerRequest) {
    const status = rootGetters.status;
    status.ordnerSidebarLoading = true;
    status.ordnerSidebarLoadingOrdid = ordnerRequest.ownerid;
    status.ordnerErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.getOrdner(ordnerRequest)
      .then((res) => {
        const ordner: Array<Ordner> = res.data;
        ordner.forEach((ord) => {
          ord.ordner = new Array<Ordner>();
          ord.docs = new Array<Doc>();
        });
        commit(MutationTypes.GetOrdnerSuccess, ordner);
      })
      .catch((error) => {
        status.ordnerErrorMsg = error.response.data.title;
      })
      .finally(() => {
        status.ordnerSidebarLoading = false;
        status.ordnerSidebarLoadingOrdid = '';
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  [ActionTypes.SetCurrentOrdner]({ commit }: ActionArguments, ordner: Ordner) {
    commit(MutationTypes.SetCurrentOrdner, ordner);
  },

  [ActionTypes.SetCurrentOrdnerFromSidebar]({ commit }: ActionArguments, ordner: Ordner) {
    commit(MutationTypes.SetCurrentOrdnerFromSidebar, ordner);
  },

  [ActionTypes.ClearOrdner]({ commit }: ActionArguments) {
    commit(MutationTypes.ClearOrdner, undefined);
  },

  [ActionTypes.ClearCurrentOrdner]({ commit }: ActionArguments) {
    commit(MutationTypes.ClearCurrentOrdner, undefined);
  },
  //#endregion

  //#region Docs
  async [ActionTypes.GetDocs]({ commit, dispatch, rootGetters }: ActionArguments, data: GetDocsRequest) {
    const status = rootGetters.status;
    status.docLoading = true;
    status.docErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.getDocs(data)
      .then((res) => {
        var docs: Array<Doc> = res.data;
        commit(MutationTypes.SetOrdnerDocs, docs);
      })
      .catch((error) => {
        status.docErrorMsg = error.response.toString();
      })
      .finally(() => {
        status.docLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.CreateDoc]({ commit, dispatch, rootGetters }: ActionArguments, data: CreateDocRequest) {
    const status = rootGetters.status;
    status.createDocLoading = true;
    status.docErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.createDoc(data)
      .then((res) => {
        var doc: Doc = res.data;

        commit(MutationTypes.AddOrUpdateDoc, doc);
      })
      .catch((error) => {
        status.docErrorMsg = error.response.toString();
      })
      .finally(() => {
        status.createDocLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.UpdateDoc]({ commit, dispatch, rootGetters }: ActionArguments, data: CRDDocRequest) {
    const status = rootGetters.status;
    status.updateDocLoading = true;
    status.docErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.updateDoc(data)
      .then((res) => {
        var doc: Doc = res.data;

        commit(MutationTypes.AddOrUpdateDoc, doc);
      })
      .catch((error) => {
        status.docErrorMsg = error.response.toString();
      })
      .finally(() => {
        status.updateDocLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.DeleteDoc]({ commit, dispatch, rootGetters }: ActionArguments, data: CRDDocRequest) {
    const status = rootGetters.status;
    status.deleteDocLoading = true;
    status.docErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.deleteDoc(data)
      .then(() => {
        commit(MutationTypes.DeleteDoc, data.doc);
      })
      .catch((error) => {
        status.docErrorMsg = error.response.toString();
      })
      .finally(() => {
        status.deleteDocLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },
  //#endregion

  //#region Comps
  [ActionTypes.GetComps]({ commit, dispatch, rootGetters }: ActionArguments, data: GetCompsRequest) {
    const status = rootGetters.status;
    status.compLoading = true;
    status.compLoadingDocid = data.docid;
    status.compErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    ArchivApiService.getComps(data)
      .then((res) => {
        const comps: Array<Comp> = res.data;
        commit(MutationTypes.SetOrdnerDocComps, comps);
      })
      .catch((error) => {
        status.compErrorMsg = error.toString();
      })
      .finally(() => {
        status.compLoading = false;
        status.compLoadingDocid = '';
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.CreateComp]({ commit, dispatch, rootGetters }: ActionArguments, data: CreateCompRequest) {
    const status = rootGetters.status;
    status.createCompLoading = true;
    status.compErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.createComp(data)
      .then((res) => {
        var comp: Comp = res.data;
        commit(MutationTypes.AddOrUpdateDocComp, comp);
      })
      .catch((error) => {
        status.compErrorMsg = error.response.toString();
      })
      .finally(() => {
        status.createCompLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.UploadCompFile]({ commit, dispatch, rootGetters }: ActionArguments, data: UploadCompFileRequest) {
    const status = rootGetters.status;
    status.updateCompLoading = true;
    status.compErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.uploadCompFile(data)
      .then((res) => {
        const comp: Comp = res.data;
        commit(MutationTypes.AddOrUpdateDocComp, comp);
      })
      .catch((error) => {
        console.log('catch');
        console.log(error);
        // status.compErrorMsg = error.response.toString();
        status.compErrorMsg = 'Es ist ein Fehler aufgetreten.';
        console.log(status.compErrorMsg);
      })
      .finally(() => {
        console.log('finally');
        status.updateCompLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.UpdateComp]({ commit, dispatch, rootGetters }: ActionArguments, data: CRDCompRequest) {
    const status = rootGetters.status;
    status.updateCompLoading = true;
    status.compErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.updateComp(data)
      .then((res) => {
        var comp: Comp = res.data;

        commit(MutationTypes.AddOrUpdateDocComp, comp);
      })
      .catch((error) => {
        console.log(error);
        status.compErrorMsg = error.response.toString();
      })
      .finally(() => {
        status.updateCompLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.DeleteComp]({ commit, dispatch, rootGetters }: ActionArguments, data: CRDCompRequest) {
    const status = rootGetters.status;
    status.deleteCompLoading = true;
    status.compErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.deleteComp(data)
      .then(() => {
        commit(MutationTypes.DeleteDocComp, data.comp);
      })
      .catch((error) => {
        status.compErrorMsg = error.response.toString();
      })
      .finally(() => {
        status.deleteCompLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },
  //#endregion
};
