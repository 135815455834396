<template>
  <!-- Delete Modal -->
  <div class="modal fade" :id="'deleteDocModal' + doc.id" tabindex="-1" :aria-labelledby="'deleteDocModal' + doc.id" aria-hidden="true" ref="deleteDocModalRef">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-danger text-white py-1">
          <strong class="modal-title my-auto" :id="'deleteDocModalLabel' + doc.id">Dokument löschen </strong>

          <button type="button" class="btn-close btn-sm" @click="closeDeleteModal()"></button>
        </div>

        <div class="modal-body" :id="'deleteDocModalBody' + doc.id">
          <div>
            <p>
              Das Dokument <strong class="text-danger">{{ doc.titel }}</strong> sowie alle Componenten werden gelöscht
            </p>
          </div>

          <div v-if="deleteDocErrorMsg != null && deleteDocErrorMsg != '' && showDeleteErrorMsg" class="alert alert-danger">
            <!-- {{ updateErrorMsg }} -->
            <!-- <br> -->
            Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm" @click="closeDeleteModal()">Abbrechen</button>
          <button type="button" class="btn btn-danger btn-sm" @click="deleteOrdner()">Dokument löschen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { ref, computed, PropType } from 'vue';
  import { useStore } from '@/store';
  import { Modal } from 'bootstrap';

  import { ActionTypes as OrdnerActionTypes } from '@/store/modules/Ordner/actions';

  import { Doc, CRDDocRequest } from '@/models/DocModels';
  //#endregion

  const props = defineProps({
    doc: {
      type: Object as PropType<Doc>,
      required: true,
    },
    archivid: {
      type: Number as PropType<number>,
      required: true,
    },
  });

  const emit = defineEmits({
    thisLoading(loading: boolean) {
      return loading;
    },
  });

  const store = useStore();

  const deleteDocErrorMsg = computed(() => store.getters.status.docErrorMsg);
  const showDeleteErrorMsg = ref(false);

  const deleteDocModalRef = ref();
  function closeDeleteModal() {
    Modal.getInstance(deleteDocModalRef.value)?.hide();
  }

  async function showDeleteModal() {
    showDeleteErrorMsg.value = false;

    if (deleteDocModalRef.value) {
      new Modal(deleteDocModalRef.value).show();
    }
  }

  function deleteOrdner() {
    emit('thisLoading', true);

    const request = new CRDDocRequest(props.doc, props.archivid);
    closeDeleteModal();
    store
      .dispatch(OrdnerActionTypes.DeleteDoc, request)
      .then(() => {
        if (deleteDocErrorMsg != null && deleteDocErrorMsg.value != '') {
          showDeleteModal();
          showDeleteErrorMsg.value = true;
        }
      })
      .finally(() => {
        emit('thisLoading', false);
      });
  }

  defineExpose({
    showDeleteModal,
  });
</script>
