import { Ordner } from '@/models/OrdnerModels';
import { Doc } from '@/models/DocModels';
import { MutationTree } from 'vuex';
import { State } from './state';
import { Comp } from '@/models/CompModels';

export enum MutationTypes {
  //#region Ordner
  GetOrdnerSuccess = 'GETORDNERSUCCESS',
  SetCurrentOrdner = 'SETCURRENTORDNER',
  SetCurrentOrdnerFromSidebar = 'SETCURRENTORDNERFROMSIDEBAR',
  ClearOrdner = 'CLEARORDNER',
  ClearCurrentOrdner = 'CLEARCURRENTORDNER',
  CreateOrdnerSuccess = 'CREATEORDNERSUCCESS',
  UpdateOrdnerSuccess = 'UPDATEORDNERSUCCESS',
  DeleteOrdnerSuccess = 'DELETEORDNERSUCCESS',
  //#endregion

  //#region Docs
  SetOrdnerDocs = 'SETORDNERDOCS',
  AddOrUpdateDoc = 'ADDORUPDATEDOC',
  AddOrUpdateDocs = 'ADDORUPDATEDOCS',
  DeleteDoc = 'DELETEDOC',
  //#endregion

  //#region Comps
  SetOrdnerDocComps = 'SETORDNERDOCCOMPS',
  AddOrUpdateDocComp = 'ADDORUPDATEDOCCOMP',
  AddOrUpdateDocComps = 'ADDORUPDATEDOCCOMPS',
  DeleteDocComp = 'DELETEDOCCOMP',
  //endregion
}

export type Mutations = {
  //#region Ordner
  [MutationTypes.GetOrdnerSuccess](state: State, ordner: Array<Ordner>): void;
  [MutationTypes.SetCurrentOrdner](state: State, ordner: Ordner): void;
  [MutationTypes.SetCurrentOrdnerFromSidebar](state: State, ordner: Ordner): void;
  [MutationTypes.ClearOrdner](state: State): void;
  [MutationTypes.ClearCurrentOrdner](state: State): void;
  [MutationTypes.CreateOrdnerSuccess](state: State, ordner: Ordner): void;
  [MutationTypes.UpdateOrdnerSuccess](state: State, ordner: Ordner): void;
  [MutationTypes.DeleteOrdnerSuccess](state: State, ordid: string): void;
  //#endregion

  //#region Docs
  [MutationTypes.SetOrdnerDocs](state: State, docs: Array<Doc>): void;
  [MutationTypes.AddOrUpdateDoc](state: State, doc: Doc): void;
  [MutationTypes.AddOrUpdateDocs](state: State, docs: Array<Doc>): void;
  [MutationTypes.DeleteDoc](state: State, doc: Doc): void;
  //#endregion

  //#region Comps
  [MutationTypes.SetOrdnerDocComps](state: State, comps: Array<Comp>): void;
  [MutationTypes.AddOrUpdateDocComp](state: State, doc: Comp): void;
  [MutationTypes.AddOrUpdateDocComps](state: State, docs: Array<Comp>): void;
  [MutationTypes.DeleteDocComp](state: State, doc: Comp): void;
  //#endregion
};

export const mutations: MutationTree<State> & Mutations = {
  //#region Ordner
  [MutationTypes.GetOrdnerSuccess](state: State, ordner: Array<Ordner>) {
    if (ordner != null && ordner.length > 0) {
      if (state.ordner == null || state.ordner.length == 0) {
        state.ordner = ordner;
      } else {
        // search parent
        const ownerid = ordner[0].ownerid;
        if (ownerid != '') {
          putInParentFolder(state.ordner, ordner);
        } else {
          state.ordner.push(...ordner);
        }
      }
    }
  },

  [MutationTypes.SetCurrentOrdner](state: State, ordner: Array<Ordner>) {
    if (!state.currentOrdner.includes(ordner)) {
      state.currentOrdner.push(ordner);
    } else {
      const index = state.currentOrdner.indexOf(ordner);
      for (let i = state.currentOrdner.length; i > index + 1; i--) {
        state.currentOrdner.pop();
      }
    }
  },

  [MutationTypes.SetCurrentOrdnerFromSidebar](state: State, ordner: Ordner) {
    // ordner ist Root, dann currentOrdner Array löschen und Root setzen
    if (ordner.ownerid == '') {
      state.currentOrdner = new Array<Ordner>();
      state.currentOrdner.push(ordner);
    }

    // ordner ist bereits in currentOrdner Array, lösche alle Unterverzeichnisse des Ordners aus currentOrdner Array
    else if (state.currentOrdner.includes(ordner)) {
      const index = state.currentOrdner.indexOf(ordner);
      for (let i = state.currentOrdner.length; i > index + 1; i--) {
        state.currentOrdner.pop();
      }
    } else {
      const parentOrdner = getParentFolder(state.ordner, ordner);

      // parent Ordner ist in currentOrdner Array
      if (parentOrdner && state.currentOrdner.includes(parentOrdner)) {
        const index = state.currentOrdner.indexOf(parentOrdner);
        for (let i = state.currentOrdner.length; i > index + 1; i--) {
          state.currentOrdner.pop();
        }
        state.currentOrdner.push(ordner);
      }

      // Ordner und Parent nicht currentOrdner Array
      else {
        const parentOrdnerArray = getFolderPathArray(state.ordner, ordner);
        state.currentOrdner = parentOrdnerArray.reverse();
      }
    }
  },

  [MutationTypes.ClearOrdner](state: State) {
    state.ordner = new Array<Ordner>();
    state.currentOrdner = new Array<Ordner>();
  },

  [MutationTypes.ClearCurrentOrdner](state: State) {
    state.currentOrdner = new Array<Ordner>();
  },

  [MutationTypes.CreateOrdnerSuccess](state: State, ordner: Ordner) {
    const ordnerArray = new Array<Ordner>();
    ordnerArray.push(ordner);

    if (state.ordner == null || state.ordner.length == 0) {
      state.ordner = ordnerArray;
    } else {
      // search parent
      const ownerid = ordner.ownerid;
      if (ownerid != '') {
        addToParentFolder(state.ordner, ordnerArray);
      } else {
        state.ordner.push(ordner);
      }
    }
  },

  [MutationTypes.UpdateOrdnerSuccess](state: State, ordner: Ordner) {
    removeFolder(state.ordner, ordner.ordid);

    const ordnerArray = new Array<Ordner>();
    ordnerArray.push(ordner);
    const ownerid = ordner.ownerid;
    if (ownerid != '') {
      addToParentFolder(state.ordner, ordnerArray);
    } else {
      state.ordner.push(ordner);
    }
  },

  [MutationTypes.DeleteOrdnerSuccess](state: State, ordid: string) {
    removeFolder(state.ordner, ordid);
  },
  //#endregion

  //#region Docs
  [MutationTypes.SetOrdnerDocs](state: State, docs: Array<Doc>) {
    if (state.currentOrdner && state.currentOrdner.length > 0) {
      state.currentOrdner[state.currentOrdner.length - 1].docs = docs;
    }
  },

  [MutationTypes.AddOrUpdateDoc](state: State, doc: Doc) {
    if (state.currentOrdner && state.currentOrdner.length > 0) {
      const index = state.currentOrdner[state.currentOrdner.length - 1].docs.findIndex((d: Doc) => d.docid == doc.docid);
      if (index != -1) {
        state.currentOrdner[state.currentOrdner.length - 1].docs.splice(index, 1, doc);
      } else {
        state.currentOrdner[state.currentOrdner.length - 1].docs.push(doc);
      }
    }
  },

  [MutationTypes.AddOrUpdateDocs](state: State, docs: Array<Doc>) {
    if (state.currentOrdner && state.currentOrdner.length > 0) {
      for (const doc of docs) {
        const index = state.currentOrdner[state.currentOrdner.length - 1].docs.findIndex((d: Doc) => d.docid == doc.docid);
        if (index != -1) {
          state.currentOrdner[state.currentOrdner.length - 1].docs.splice(index, 1, doc);
        } else {
          state.currentOrdner[state.currentOrdner.length - 1].docs.push(doc);
        }
      }
    }
  },

  [MutationTypes.DeleteDoc](state: State, doc: Doc) {
    if (state.currentOrdner && state.currentOrdner.length > 0) {
      const index = state.currentOrdner[state.currentOrdner.length - 1].docs.findIndex((d: Doc) => d.docid == doc.docid);
      if (index != -1) {
        state.currentOrdner[state.currentOrdner.length - 1].docs.splice(index, 1);
      }
    }
  },
  //#endregion

  //#region Comps
  [MutationTypes.SetOrdnerDocComps](state: State, comps: Array<Comp>) {
    const docIndex = state.currentOrdner[state.currentOrdner.length - 1].docs.findIndex((d: Doc) => d.docid == comps[0].docid);
    if (docIndex != -1) {
      state.currentOrdner[state.currentOrdner.length - 1].docs[docIndex].comps = comps;
    }
  },

  [MutationTypes.AddOrUpdateDocComp](state: State, comp: Comp) {
    if (state.currentOrdner && state.currentOrdner.length > 0) {
      const docIndex = state.currentOrdner[state.currentOrdner.length - 1].docs.findIndex((d: Doc) => d.docid == comp.docid);
      if (docIndex != -1) {
        const compIndex = state.currentOrdner[state.currentOrdner.length - 1].docs[docIndex].comps.findIndex((c: Comp) => c.compid == comp.compid);
        if (compIndex != -1) {
          state.currentOrdner[state.currentOrdner.length - 1].docs[docIndex].comps.splice(compIndex, 1, comp);
        } else {
          state.currentOrdner[state.currentOrdner.length - 1].docs[docIndex].comps.push(comp);
        }
      }
    }
  },

  [MutationTypes.AddOrUpdateDocComps](state: State, comps: Array<Comp>) {
    if (state.currentOrdner && state.currentOrdner.length > 0) {
      for (const comp of comps) {
        const docIndex = state.currentOrdner[state.currentOrdner.length - 1].docs.findIndex((d: Doc) => d.docid == comp.docid);
        if (docIndex != -1) {
          const compIndex = state.currentOrdner[state.currentOrdner.length - 1].docs[docIndex].comps.findIndex((c: Comp) => c.compid == comp.compid);
          if (compIndex != -1) {
            state.currentOrdner[state.currentOrdner.length - 1].docs[docIndex].comps.splice(compIndex, 1, comp);
          } else {
            state.currentOrdner[state.currentOrdner.length - 1].docs[docIndex].comps.push(comp);
          }
        }
      }
    }
  },

  [MutationTypes.DeleteDocComp](state: State, comp: Comp) {
    if (state.currentOrdner && state.currentOrdner.length > 0) {
      const docIndex = state.currentOrdner[state.currentOrdner.length - 1].docs.findIndex((d: Doc) => d.docid == comp.docid);
      if (docIndex != -1) {
        const compIndex = state.currentOrdner[state.currentOrdner.length - 1].docs[docIndex].comps.findIndex((c: Comp) => c.compid == comp.compid);
        if (compIndex != -1) {
          state.currentOrdner[state.currentOrdner.length - 1].docs[docIndex].comps.splice(compIndex, 1);
        }
      }
    }
  },
  //#endregion
};

// replaces Subfolder List (when folder tree gets build or updated)
function putInParentFolder(foldersArray: Array<Ordner>, ordner: Array<Ordner>): void {
  for (const folder of foldersArray) {
    if (folder.ordid == ordner[0].ownerid) {
      folder.ordner = ordner;
      return;
    } else {
      if (folder.ordner != null && folder.ordner != undefined && folder.ordner.length > 0) {
        putInParentFolder(folder.ordner, ordner);
      }
    }
  }
}

// adds Folderlist to Subfolders (when new folders are added)
function addToParentFolder(foldersArray: Array<Ordner>, ordner: Array<Ordner>): void {
  foldersArray.forEach((folder) => {
    if (folder.ordid == ordner[0].ownerid) {
      for (const ord of ordner) {
        const index = folder.ordner.findIndex((f) => f.ordid == ord.ordid);
        if (index != -1) {
          folder.ordner.splice(index, 1, ord);
        } else {
          folder.ordner.push(ord);
        }
      }
      // folder.ordner.push(...ordner);
      return;
    } else if (folder.ordner != null && folder.ordner.length > 0) {
      addToParentFolder(folder.ordner, ordner);
    }
  });
}

function removeFolder(foldersArray: Array<Ordner>, ordid: string): void {
  const index = foldersArray.findIndex((f) => f.ordid == ordid);
  if (index != -1) {
    foldersArray.splice(index, 1);
    return;
  } else {
    foldersArray.forEach((folder) => {
      if (folder.ordner != null && folder.ordner.length > 0) {
        removeFolder(folder.ordner, ordid);
      }
    });
  }
}

function getParentFolder(foldersArray: Array<Ordner>, ordner: Ordner): Ordner | undefined {
  if (foldersArray.length == 0) {
    return undefined;
  }

  let response = foldersArray.find((ord) => ord.ordid == ordner.ownerid);
  if (response != undefined) {
    return response;
  }

  for (var ord of foldersArray) {
    if (response != undefined) {
      break;
    }

    response = getParentFolder(ord.ordner, ordner);
  }

  return response;
}

function getFolderPathArray(foldersArray: Array<Ordner>, ordner: Ordner): Array<Ordner> {
  const response = new Array<Ordner>();
  response.push(ordner);

  var currentOrdner = ordner;
  while (currentOrdner.ownerid != '') {
    var currentOrdnerParent = getParentFolder(foldersArray, currentOrdner);
    if (currentOrdnerParent != undefined) {
      currentOrdner = currentOrdnerParent;
      response.push(currentOrdner);
    } else {
      break;
    }
  }

  return response;
}
