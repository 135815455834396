<template>
  <div class="toast-container position-fixed bottom-0 end-0 p-3">
    <UploadNotification
      v-for="(notification, index) in notifications"
      :key="index"
      :message="notification.message"
      :background="notification.background"
      :loading="notification.loading"
      @dismiss="removeNotification(notification.guid)"
    />
  </div>
</template>

<script setup lang="ts">
  import { reactive } from 'vue';
  import { v4 as uuidv4 } from 'uuid';

  import UploadNotification from './UploadNotification.vue';

  interface NotificationData {
    guid: string;
    message: string;
    background: string;
    loading: boolean;
  }

  const notifications = reactive<NotificationData[]>([]);

  function addNotification(message: string, loading: boolean = false, background: string = 'text-bg-primary') {
    const guid = uuidv4();
    notifications.push({ guid, message, loading, background });
    return guid;
  }

  function removeNotification(guid: string) {
    const index = notifications.findIndex((notification) => notification.guid === guid);
    console.log(index);
    if (index != -1) {
      notifications.splice(index, 1);
    }
  }

  defineExpose({
    addNotification,
    removeNotification,
  });
</script>

<style scoped></style>
