<template>
  <div class="modal fade" id="newDocModal" tabindex="-1" aria-labelledby="newDocModal" aria-hidden="true" ref="newDocModalRef">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header py-1">
          <span class="modal-title my-auto" id="newDocModalLabel">Dokument erstellen</span>
          <button type="button" class="btn-close" aria-label="Close" @click="closeModal()"></button>
        </div>
        <div class="modal-body">
          <LoadingSpinner v-if="loading" text="...Dokument wird erstellt..." />

          <form v-else @submit.prevent="createNewDoc()">
            <div class="mb-3">
              <label for="fileupload" class="form-label">Datei</label>
              <input type="file" class="form-control" id="fileupload" @change="handleFileUpload" />
            </div>

            <div v-if="fileSizeErrorMessage != ''" class="alert alert-danger mb-3">
              {{ fileSizeErrorMessage }}
            </div>

            <div v-else class="mb-3">
              <label for="newDocTitelInput" class="form-label">Titel</label>
              <input type="text" class="form-control" id="newDocTitelInput" v-model="newDocTitel" />
            </div>
            <p class="text-danger" v-show="newDocTitelError != ''">{{ newDocTitelError }}</p>

            <div v-if="errorMsg != ''" class="alert alert-danger">{{ errorMsg }}</div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-secondary me-3" @click="closeModal()">Abbrechen</button>
              <button type="submit" class="btn btn-primary">Speichern</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { ref, computed, PropType } from 'vue';
  import { useStore } from '@/store';
  import { Modal } from 'bootstrap';

  import { ActionTypes as OrdnerActionTypes } from '@/store/modules/Ordner/actions';

  import { CreateDocData, CreateDocRequest, Doc } from '@/models/DocModels';
  import { Archiv } from '@/models/ArchivModels';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';

  //#endregion

  const { archiv } = defineProps({
    archiv: {
      type: Object as PropType<Archiv>,
      required: true,
    },
  });

  const store = useStore();

  const currentOrdner = computed(() => store.getters.currentOrdner);

  const loading = computed(() => store.getters.status.createDocLoading);
  const errorMsg = computed(() => store.getters.status.docErrorMsg);

  const newDocTitel = ref('Neues Dokument');
  const newDocTitelError = ref('');
  const newDocModalRef = ref();

  function closeModal() {
    newDocTitel.value = 'Neues Dokument';
    newDocTitelError.value = '';
    Modal.getInstance(newDocModalRef.value)?.hide();
  }

  function showModal() {
    if (newDocModalRef.value) {
      new Modal(newDocModalRef.value).show();
    }
  }

  const MAX_FILE_SIZE = 50 * 1024 * 1024;
  const file = ref<File | null>(null);
  const fileSizeErrorMessage = ref('');

  function handleFileUpload(event: Event) {
    fileSizeErrorMessage.value = '';

    const inputElement = event.target as HTMLInputElement;
    const selectedfile = inputElement.files![0];

    if (selectedfile) {
      file.value = selectedfile;

      if (newDocTitel.value == '') {
        newDocTitel.value = selectedfile.name;
      }
    }

    if (file.value != null && file.value.size > MAX_FILE_SIZE) {
      fileSizeErrorMessage.value = 'Die Anlagengröße überschreitet das erlaubte Maximum. Die maximale Größe ist 25MB.';
    }
  }

  function createNewDoc() {
    newDocTitelError.value = '';
    fileSizeErrorMessage.value = '';

    if (file.value == null || file.value.size > MAX_FILE_SIZE) {
      fileSizeErrorMessage.value = 'Bitte wählen Sie eine Datei aus';
      return;
    }

    if (archiv != null && currentOrdner.value.length > 0) {
      const doc = new Doc();
      doc.titel = newDocTitel.value;
      doc.ordid = currentOrdner.value[currentOrdner.value.length - 1].ordid;

      const data: CreateDocData = {
        doc: JSON.parse(JSON.stringify(doc)),
        file: file.value,
      };

      const request: CreateDocRequest = {
        data: data,
        archivid: archiv.id,
      };

      store.dispatch(OrdnerActionTypes.CreateDoc, request).then(() => {
        if (errorMsg.value != '') {
          newDocTitelError.value = 'Es ist ein Fehler aufgetreten';
        } else {
          closeModal();
        }
      });
    } else {
      newDocTitelError.value = 'Bitte wählen Sie einen Ordner aus';
    }
  }

  defineExpose({
    showModal,
  });
</script>
