import { Doc } from './DocModels';

export class Ordner {
  id: number;
  ownerid: string;
  ordid: string;
  adate: Date;
  mdate: Date;
  vdate: Date | null;
  edate: Date | null;
  ddate: Date | null;
  autor: string;
  titel: string;
  titel_1: string;
  titel_2: string;
  titel_3: string;
  titel_4: string;
  titel_5: string;
  barcode: string;
  klassen: string;
  visible: string;
  status: number | null;
  cryid: number | null;
  keyid: number | null;
  ordner: Array<Ordner>;
  docs: Array<Doc>;

  constructor() {
    this.id = 0;
    this.ownerid = '';
    this.ordid = '';
    this.adate = new Date();
    this.mdate = new Date();
    this.vdate = new Date();
    this.edate = new Date();
    this.ddate = new Date();
    this.autor = '';
    this.titel = '';
    this.titel_1 = '';
    this.titel_2 = '';
    this.titel_3 = '';
    this.titel_4 = '';
    this.titel_5 = '';
    this.barcode = '';
    this.klassen = '';
    this.visible = '';
    this.status = 0;
    this.cryid = 0;
    this.keyid = 0;
    this.ordner = new Array<Ordner>();
    this.docs = new Array<Doc>();
  }
}

export class GetOrdnerRequest {
  archivid: number;
  ownerid: string;

  constructor(archivid: number, ownerid: string) {
    this.archivid = archivid;
    this.ownerid = ownerid;
  }
}

export class CRDOrdnerRequest {
  ordner: Ordner;
  archivid: number;

  constructor(ordner: Ordner, archivid: number) {
    this.ordner = ordner;
    this.archivid = archivid;
  }
}

export class CreateOrdnerPfadRequest {
  archivid: number;
  ordnerPfad: string;
  ownerid: string | null;

  constructor(archivid: number, ordnerPfad: string, ownerid: string | null) {
    this.archivid = archivid;
    this.ordnerPfad = ordnerPfad;
    this.ownerid = ownerid;
  }
}
