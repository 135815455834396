<template>
  <div
    class="modal fade"
    :id="'updateCompModal' + comp.id"
    tabindex="-1"
    :aria-labelledby="'updateCompModal' + comp.id"
    aria-hidden="true"
    ref="updateCompModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header py-1">
          <span class="modal-title my-auto" :id="'updateCompModalLabel' + comp.id"> Ordner bearbeiten </span>

          <button type="button" class="btn-close btn-sm" @click="closeUpdateModal()"></button>
        </div>

        <div class="modal-body" :id="'updateCompModalBody' + comp.id">
          <LoadingSpinner v-if="updateLoading" />

          <div v-else>
            <div class="mb-3">
              <label :for="'updateCompTitelInput_' + comp.id" class="form-label">Titel</label>
              <input type="text" class="form-control" :id="'updateFolderTitelInput_' + comp.id" v-model="updateComp.titel" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderBarcodeInput_' + comp.id" class="form-label">Barcode</label>
              <input type="text" class="form-control" :id="'updateFolderBarcodeInput_' + comp.id" v-model="updateComp.barcode" />
            </div>
          </div>

          <div v-if="updateErrorMsg != null && updateErrorMsg != '' && showUpdateErrorMsg" class="alert alert-danger">
            <!-- {{ updateErrorMsg }} -->
            <!-- <br> -->
            Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
          </div>
        </div>

        <div class="modal-footer" v-if="!updateLoading">
          <button type="button" class="btn btn-secondary btn-sm" @click="closeUpdateModal()">Abbrechen</button>
          <button type="button" class="btn btn-primary btn-sm" @click="saveChanges()">Änderungen speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { ref, computed, PropType } from 'vue';
  import { useStore } from '@/store';
  import { Modal } from 'bootstrap';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';

  import { ActionTypes as OrdnerActionTypes } from '@/store/modules/Ordner/actions';

  import { Comp, CRDCompRequest } from '@/models/CompModels';
  //#endregion

  const props = defineProps({
    comp: {
      type: Object as PropType<Comp>,
      required: true,
    },
    archivid: {
      type: Number as PropType<number>,
      required: true,
    },
  });

  const store = useStore();

  const updateLoading = computed(() => store.getters.status.updateCompLoading);
  const updateErrorMsg = computed(() => store.getters.status.compErrorMsg);
  const showUpdateErrorMsg = ref(false);

  const updateComp = ref(JSON.parse(JSON.stringify(props.comp)));

  function resetForm() {
    updateComp.value = JSON.parse(JSON.stringify(props.comp));
  }

  const updateCompModalRef = ref();
  function closeUpdateModal() {
    resetForm();
    Modal.getInstance(updateCompModalRef.value)?.hide();
  }

  function showUpdateModal() {
    showUpdateErrorMsg.value = false;

    if (updateCompModalRef.value) {
      new Modal(updateCompModalRef.value).show();
    }
  }

  function saveChanges() {
    showUpdateErrorMsg.value = false;

    const request = new CRDCompRequest(updateComp.value, props.archivid);

    store.dispatch(OrdnerActionTypes.UpdateComp, request).then(() => {
      if (updateErrorMsg.value == null || updateErrorMsg.value == '') {
        closeUpdateModal();
      } else {
        showUpdateErrorMsg.value = true;
      }
    });
  }

  defineExpose({
    showUpdateModal,
  });
</script>
