
<template>
  <div class="text-center my-5">
    <div class="spinner-border text-primary mb-3" role="status">
      <span class="sr-only"></span>
    </div>

    <p class="text-muted">{{text}}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingSpinner",
  props: {
    text: {
      type: String,
      default: "...Daten werden geladen..."
    }
  },
})
</script>

<style>

</style>