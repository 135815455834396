import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from './mutations';

import { RootState } from '@/store';
import { ActionTypes as CommonActionTypes } from '@/store/modules/Common/actions';

import { State } from './state';
import { Comp, DownloadCompData, DownloadCompRequest } from '@/models/CompModels';

import ArchivApiService from '@/service/ArchivApiService';

import { ArchivComponenteFile } from '@/models/CompModels';

export enum ActionTypes {
  DownloadComp = 'DOWNLOADCOMP',
}

type ActionArguments = Omit<ActionContext<State, RootState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.DownloadComp](context: ActionArguments, data: DownloadCompData): Promise<void>;
};

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.DownloadComp]({ commit, dispatch, rootGetters }: ActionArguments, data: DownloadCompData) {
    const status = rootGetters.status;
    status.compDownloadLoading = true;
    status.compErrorMsg == '';
    dispatch(CommonActionTypes.SetStatus, status);

    const requestData: DownloadCompRequest = new DownloadCompRequest(data.archivmandantid, data.archivname, data.componente.docid, data.componente.compid);
    // const componente: Comp = data.componente;
    const componente: Comp = JSON.parse(JSON.stringify(data.componente));

    return ArchivApiService.getCompFile(requestData)
      .then(async (res) => {
        const base64String = await blobToBase64(res.data);
        const doc = new ArchivComponenteFile(res.data.size, base64String);
        componente.file = doc;
        commit(MutationTypes.ComponentenSuccess, componente);
      })
      .catch((error) => {
        console.log(error);
        const status = rootGetters.status;
        status.compErrorMsg == error.toString();
      })
      .finally(() => {
        status.compDownloadLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },
};

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      if (typeof result === 'string') {
        resolve(result.split(',')[1]); // Strip out the data URL part
      } else {
        reject('Failed to convert blob to base64 string');
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
