<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-primary shadow-sm py-1 mb-5">
    <div class="container">

      <router-link :to="{ name: 'Home' }" class="navbar-brand">
        <img src="@/assets/icons/archiv.png" class="me-3" width="40">
        1.A Archiv
      </router-link>
      
      <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

          <li class="nav-item my-auto ms-5">
            <router-link :to="{ name: 'Login' }" class="nav-link"><font-awesome-icon icon="arrow-right-to-bracket" class="me-2" /> Anmelden</router-link>
          </li>
        </ul>
      </div>

    </div>
  </nav>

  <div class="container">

    <div class="row mb-5">
      <div class="col-7 pe-5 my-auto">
        <h1>
          1.A-Archiv Dokumenten-Management-System
        </h1>
        <p class="lead">
          Das 1.A Archiv ist eine umfassende Lösung für das digitale Management und die rechtskonforme Archivierung von Dokumenten und Daten in Unternehmen und in der Verwaltung.
          Die Software ist flexibel skalierbar und bereits mit dem ersten Nutzer stehen alle Funktionen zur Verfügung.
        </p>

        <p>
          <a href="https://1aconnect.de/kontakt" target="_blank" class="btn btn-primary my-2 me-2">Kontakt</a>
          <a href="https://1aconnect.de/archiv" target="_blank" class="btn btn-secondary my-2">Weitere Informationen</a>
        </p>
      </div>

      <div class="col-5">
        <div class="card">
          <div class="card-header bg-primary">
            <h5 class="py-0 my-0 text-white">Anmeldung</h5>
          </div>
          <div class="card-body">
            <p class="card-text">
              Geben Sie hier Ihre Firmenkennung ein:
            </p>

            <form @submit.prevent="goToLoginPage()">
              <div class="mb-3">
                <label for="firmenkennungInput" class="form-label">Firmenkennung</label>
                <input type="text" class="form-control" id="firmenkennungInput" v-model="firma" required>
              </div>

              <button type="submit" class="btn btn-primary"><font-awesome-icon icon="arrow-right-to-bracket" class="me-2" />Weiter zur Anmeldung</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center py-4 footer-bg">
      <a href="https://1aconnect.de/impressum" target="_blank" class="me-3">Impressum</a> |
      <a href="https://1aconnect.de/datenschutzerklaerung" target="_blank" class="ms-3">Datenschutz</a>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Start",

  setup () {
    const route = useRoute();

    console.log(window.location.hostname);

    const firma = ref("")

    return {
      route,
      firma
    }
  },

  methods: {
    goToLoginPage() {
      window.open(`https://${this.firma.trim().toLowerCase()}.1a-dms.de/login`, "_self")
    }
  }
})
</script>