<template>
  <!-- <nav id="sidebar" class="d-flex flex-column flex-shrink-0 p-3 bg-primary text-light shadow text-start" :class="sidebarCollapsed ? 'sidebar-collapsed' : 'sidebar-notcollapsed p-3'"> -->
  <nav id="sidebar" class="d-flex flex-column flex-shrink-0 p-3 bg-primary text-light shadow text-start">
    <div v-show="showSidebarContent">
      <router-link :to="{ name: 'Home' }" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none text-light">
        <!-- <font-awesome-icon icon="users-line" class="me-3 fa-2x" /> -->
        <img src="@/assets/icons/archiv.png" class="me-3" width="40" />
        <span class="fs-4">Archiv</span>
      </router-link>

      <hr />
      <div v-if="selectedArchiv != undefined">
        <router-link :to="{ name: 'Home' }"
          ><button class="btn btn-outline-light w-100"><font-awesome-icon icon="chevron-left" class="me-2" /> Archive</button></router-link
        >
        <hr class="my-3" />

        <!-- <p v-for="ord in sortedOrdner" :key="ord.id">{{ ord.titel }}</p> -->
        <OrdnerTreeNode v-for="ord in sortedOrdner" :key="ord.id" :ordner="ord" />
        <!-- {{ selectedArchiv }} -->
      </div>

      <div v-else-if="!archivLoading && selectedArchiv == undefined">
        <select class="form-select mb-3 rounded-0" v-model="currentMandant" aria-label="Mandant wählen" @change="updateSelectedMandant()">
          <option v-for="mandant in mandanten" :key="mandant.id" :value="mandant">{{ mandant.name }}</option>
        </select>

        <ul class="nav nav-pills flex-column mb-auto">
          <li class="nav-item mb-3" v-for="archiv in filteredArchive" :key="archiv.id">
            <router-link :to="'/archiv/' + archiv.archivname + '?id=' + archiv.id" class="nav-link text-light rounded-0" active-class="active">
              <font-awesome-icon icon="box-archive" class="me-3 fa-lg" />
              {{ archiv.archivname }}
            </router-link>
          </li>
        </ul>
      </div>

      <hr />

      <a href="https://1a-install.de/UpdateService.application" target="_blank" class="btn btn-outline-light w-100">Desktop App herunterladen</a>
    </div>
    <div id="dragHandler" v-if="!sidebarCollapsed"></div>
  </nav>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, watchEffect } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { ActionTypes as ArchivActionTypes } from '@/store/modules/Archiv/actions';

  import OrdnerTreeNode from '@/components/SidebarTree/OrdnerTreeNode.vue';

  export default defineComponent({
    setup() {
      const store = useStore();
      const router = useRouter();

      const sidebarCollapsed = computed(() => store.getters.status.sidebarCollapsed);
      const showSidebarContent = ref(true);

      const archivLoading = computed(() => store.getters.status.archivLoading);
      const selectedArchiv = computed(() => store.getters.selectedArchiv);
      const archive = computed(() => store.getters.archive);
      const filteredArchive = computed(() =>
        archive.value.filter((a) => a.mandantid == selectedMandant.value.id).sort((a, b) => (a.archivname.toLowerCase() < b.archivname.toLowerCase() ? -1 : 1))
      );
      const mandanten = computed(() => store.getters.mandanten);

      const ordner = computed(() => store.getters.ordner);
      const sortedOrdner = computed(() => ordner.value.sort((a, b) => a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())));

      // const currentOrdner = computed(() => store.getters.currentOrdner);

      const selectedMandant = computed(() => {
        currentMandant.value = store.getters.selectedMandant;
        return store.getters.selectedMandant;
      });
      const currentMandant = ref();

      const isResizing = ref(false);
      const sidebar = ref(document.getElementById('sidebar'));
      const sidebarWidth = ref(280);
      const dragHandler = ref(document.getElementById('dragHandler'));
      const sidebarResizeObserver = ref(new ResizeObserver(() => {}));

      watchEffect(() => {
        if (sidebar.value) {
          if (sidebarCollapsed.value) {
            sidebar.value.style.width = '';
            showSidebarContent.value = false;
            sidebar.value.classList.add('sidebar-collapsed');
          } else {
            sidebar.value.classList.remove('sidebar-collapsed');
            showSidebarContent.value = true;
            sidebar.value.style.width = `${sidebarWidth.value}px`;
          }
        }
      });

      return {
        store,
        router,
        sidebarCollapsed,
        showSidebarContent,
        archivLoading,
        selectedArchiv,
        archive,
        filteredArchive,
        mandanten,
        selectedMandant,
        currentMandant,
        sortedOrdner,
        isResizing,
        sidebar,
        sidebarWidth,
        dragHandler,
        sidebarResizeObserver,
      };
    },

    components: {
      OrdnerTreeNode,
    },

    methods: {
      updateSelectedMandant() {
        this.store.dispatch(ArchivActionTypes.SetSelectedMandant, this.currentMandant).then(() => {
          this.currentMandant = this.selectedMandant;
          this.router.push('/');
        });
      },

      initSidebarResizeListener() {
        this.sidebarResizeObserver.disconnect();
        this.sidebar = document.getElementById('sidebar');
        this.dragHandler = document.getElementById('dragHandler');

        if (this.sidebar && this.dragHandler) {
          this.dragHandler.addEventListener('mousedown', (_event: MouseEvent) => {
            this.isResizing = true;
            document.addEventListener('mousemove', this.handleMouseMove);

            document.addEventListener('mouseup', () => {
              this.isResizing = false;
              document.removeEventListener('mousemove', this.handleMouseMove);
            });
          });
        }
      },

      handleMouseMove(event: MouseEvent) {
        if (this.isResizing && this.sidebar) {
          let newWidth = event.clientX - this.sidebar.getBoundingClientRect().left;
          if (newWidth > 200 && newWidth < 800) {
            this.sidebarWidth = newWidth;
            this.showSidebarContent = this.sidebarWidth > 200;
            this.sidebar.style.width = `${this.sidebarWidth}px`;
          }
        }
      },
    },

    mounted() {
      this.initSidebarResizeListener();
    },

    updated() {
      this.initSidebarResizeListener();
    },
  });
</script>

<style lang="scss" scoped>
  #sidebar {
    position: relative;
  }

  .sidebar-collapsed {
    width: 10px !important;
    transition: width 0.3s ease-in-out;
  }

  .active {
    background: #fff !important;
    color: #2a579b !important;
  }

  .btn-toggle[aria-expanded='true']::after {
    transform: rotate(-180deg);
  }

  .btn-toggle::after {
    width: 1.25em;
    margin: auto 0 auto 5em;
    line-height: 0;
    transition: transform 0.35s ease;
    content: url(../assets/icons/chevron-down.svg);
  }

  #dragHandler {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    height: 100%;
    width: 4px;
    cursor: ew-resize;
  }
</style>
<!-- content: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e); -->
