import { ArchivRechte } from '@/models/ArchivModels';

export function archivHelperfunction() {
  function getArchivrechte(archivrechte: string): ArchivRechte {
    const rechte = new ArchivRechte();

    if (archivrechte.length >= 20) {
      rechte.dokumentRead = archivrechte[0] === '1';
      rechte.dokumentUpdate = archivrechte[1] === '1';
      rechte.dokumentCreate = archivrechte[2] === '1';
      rechte.dokumentDelete = archivrechte[3] === '1';

      rechte.ordnerRead = archivrechte[4] === '1';
      rechte.ordnerUpdate = archivrechte[5] === '1';
      rechte.ordnerCreate = archivrechte[6] === '1';
      rechte.ordnerDelete = archivrechte[7] === '1';

      rechte.adminVorlagen = archivrechte[8] === '1';
      rechte.adminSuchUndSchlagwort = archivrechte[9] === '1';
      rechte.adminTitelUndEmail = archivrechte[10] === '1';
      rechte.adminDeletedObjekte = archivrechte[11] === '1';

      rechte.klassenReplikation = archivrechte[12] === '1';
      rechte.klassenAdmin = archivrechte[13] === '1';

      rechte.klassenFrei1 = archivrechte[14] === '1';
      rechte.klassenFrei2 = archivrechte[15] === '1';
      rechte.klassenFrei3 = archivrechte[16] === '1';
      rechte.klassenFrei4 = archivrechte[17] === '1';
      rechte.klassenFrei5 = archivrechte[18] === '1';
      rechte.klassenFrei6 = archivrechte[19] === '1';
    }

    return rechte;
  }

  return {
    getArchivrechte,
  };
}
