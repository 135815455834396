import { ActionContext, ActionTree } from 'vuex';
import ArchivApiService from '@/service/ArchivApiService';

import { RootState } from '@/store';

import { State } from './state';

import { ActionTypes as CommonActionTypes } from '@/store/modules/Common/actions';
import { Mutations, MutationTypes } from './mutations';
import { AuthUser, LoginRequest, LoginAzureRequest, AzureConfigEinstellung, LogoutRequest, RefreshLoginRequest } from '@/models/AuthModels';
import { ErrorResponseData } from '@/models/CommonModels';

export enum ActionTypes {
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  LoginAzure = 'LOGINAZURE',
  ValidateOTP = 'VALIDATEOTP',
  RefreshLogin = 'REFRESHLOGIN',
  GetAzureLoginConfig = 'GETAZURELOGINCONFIG',
  SetAzureLoginAvailable = 'SETAZURELOGINAVAILABLE',
}

type ActionArguments = Omit<ActionContext<State, RootState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.Login](context: ActionArguments, data: LoginRequest): Promise<void>;
  [ActionTypes.Logout](context: ActionArguments): Promise<void>;
  [ActionTypes.LoginAzure](context: ActionArguments, azureLogin: LoginAzureRequest): Promise<void>;
  [ActionTypes.ValidateOTP](context: ActionArguments, data: LoginRequest): Promise<void>;
  [ActionTypes.RefreshLogin](context: ActionArguments): Promise<void>;
  [ActionTypes.GetAzureLoginConfig](context: ActionArguments): void;
  [ActionTypes.SetAzureLoginAvailable](context: ActionArguments): void;
};

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.Login]({ commit, dispatch, rootGetters }: ActionArguments, data: LoginRequest) {
    const status = rootGetters.status;
    status.authLoading = true;
    status.authErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.login(data)
      .then((res) => {
        const user: AuthUser = res.data;
        commit(MutationTypes.LoginSuccess, user);
      })
      .catch((error) => {
        var errorresponse = (error.response?.data as ErrorResponseData) || null;
        status.authErrorMsg = errorresponse.title;
        localStorage.removeItem('authUser');
      })
      .finally(() => {
        status.authLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  [ActionTypes.Logout]({ commit, dispatch, rootGetters }: ActionArguments) {
    const status = rootGetters.status;
    status.authLoading = true;
    status.authErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.logout(new LogoutRequest(rootGetters.authUser.refreshToken))
      .then(() => {
        console.log('LOGOUT');
      })
      .catch((error) => {
        console.log('LOGOUT CATCH');
        console.log(error);
        // status.authLoading = false;
      })
      .finally(() => {
        commit(MutationTypes.SetAzureLogin, false);
        dispatch(CommonActionTypes.SetStatus, status);
        sessionStorage.clear();
        let azureconfig = rootGetters.azureconfig;
        let msalConfig = rootGetters.msalConfig;
        let secureBrowserToken = localStorage.getItem('SECBROWSTOKEN');
        let archivSortBy = localStorage.getItem('archivSortBy');

        localStorage.clear();
        localStorage.setItem('azureconfig', JSON.stringify(azureconfig));
        localStorage.setItem('msalconfig', JSON.stringify(msalConfig));

        if (archivSortBy) {
          localStorage.setItem('archivSortBy', archivSortBy);
        }

        if (secureBrowserToken != null) {
          localStorage.setItem('SECBROWSTOKEN', secureBrowserToken);
        }

        status.authLoading = false;
        commit(MutationTypes.Logout, undefined);
      });
  },

  [ActionTypes.LoginAzure]({ commit, dispatch, rootGetters }: ActionArguments, azureLogin: LoginAzureRequest) {
    const status = rootGetters.status;
    status.authLoading = true;
    status.authErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetAzureUsername, azureLogin.username);
    localStorage.setItem('azureusername', JSON.stringify(azureLogin.username));

    return ArchivApiService.loginAzure(azureLogin.idToken)
      .then((res) => {
        commit(MutationTypes.SetAzureLogin, true);

        const user: AuthUser = res.data;
        commit(MutationTypes.LoginSuccess, user);
      })
      .catch((error) => {
        status.authErrorMsg = error.response.data.title;
        localStorage.removeItem('authUser');
      })
      .finally(() => {
        status.authLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.ValidateOTP]({ commit, dispatch, rootGetters }: ActionArguments, data: LoginRequest) {
    const status = rootGetters.status;
    status.authLoading = true;
    status.authErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.validateOTPLogin(data)
      .then((res) => {
        const user: AuthUser = res.data;
        commit(MutationTypes.LoginSuccess, user);
      })
      .catch((error) => {
        var errorresponse = (error.response?.data as ErrorResponseData) || null;
        status.authErrorMsg = errorresponse.title;
        localStorage.removeItem('authUser');
      })
      .finally(() => {
        status.authLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.RefreshLogin]({ commit, rootGetters }: ActionArguments) {
    const request = new RefreshLoginRequest(rootGetters.authUser.refreshToken);
    const res = await ArchivApiService.refreshLogin(request);

    const result: AuthUser = res.data;
    commit(MutationTypes.RefreshLoginSuccess, result);

    return;
  },

  async [ActionTypes.GetAzureLoginConfig]({ commit }: ActionArguments) {
    ArchivApiService.getAzureLoginConfig()
      .then((res) => {
        const azureconfig: AzureConfigEinstellung = res.data;
        commit(MutationTypes.GetAzureLoginConfigSuccess, azureconfig);
        localStorage.setItem('azureconfig', JSON.stringify(azureconfig));
      })
      .catch(() => {
        localStorage.removeItem('azureconfig');
        // console.error(error)
      });
  },

  [ActionTypes.SetAzureLoginAvailable]({ commit }: ActionArguments) {
    commit(MutationTypes.SetAzureLoginAvailable, undefined);
  },
};
