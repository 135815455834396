<template>
  <div class="modal fade" :id="'updateDocModal' + doc.id" tabindex="-1" :aria-labelledby="'updateDocModal' + doc.id" aria-hidden="true" ref="updateDocModalRef">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header py-1">
          <span class="modal-title my-auto" :id="'updateDocModalLabel' + doc.id"> Ordner bearbeiten </span>

          <button type="button" class="btn-close btn-sm" @click="closeUpdateModal()"></button>
        </div>

        <div class="modal-body" :id="'updateDocModalBody' + doc.id">
          <LoadingSpinner v-if="updateLoading" />

          <div v-else>
            <div class="mb-3">
              <label :for="'updateFolderTitelInput_' + doc.id" class="form-label">Titel</label>
              <input type="text" class="form-control" :id="'updateFolderTitelInput_' + doc.id" v-model="updateDoc.titel" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_1Input_' + doc.id" class="form-label">Titel 1</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_1Input_' + doc.id" v-model="updateDoc.titel_1" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_2Input_' + doc.id" class="form-label">Titel 2</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_2Input_' + doc.id" v-model="updateDoc.titel_2" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_3Input_' + doc.id" class="form-label">Titel 3</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_3Input_' + doc.id" v-model="updateDoc.titel_3" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_4Input_' + doc.id" class="form-label">Titel 4</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_4Input_' + doc.id" v-model="updateDoc.titel_4" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderTitel_5Input_' + doc.id" class="form-label">Titel 5</label>
              <input type="text" class="form-control" :id="'updateFolderTitel_5Input_' + doc.id" v-model="updateDoc.titel_5" />
            </div>

            <div class="mb-3">
              <label :for="'updateFolderBarcodeInput_' + doc.id" class="form-label">Barcode</label>
              <input type="text" class="form-control" :id="'updateFolderBarcodeInput_' + doc.id" v-model="updateDoc.barcode" />
            </div>
          </div>

          <div v-if="updateErrorMsg != null && updateErrorMsg != '' && showUpdateErrorMsg" class="alert alert-danger">
            <!-- {{ updateErrorMsg }} -->
            <!-- <br> -->
            Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
          </div>
        </div>

        <div class="modal-footer" v-if="!updateLoading">
          <button type="button" class="btn btn-secondary btn-sm" @click="closeUpdateModal()">Abbrechen</button>
          <button type="button" class="btn btn-primary btn-sm" @click="saveChanges()">Änderungen speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { ref, computed, PropType } from 'vue';
  import { useStore } from '@/store';
  import { Modal } from 'bootstrap';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';

  import { ActionTypes as OrdnerActionTypes } from '@/store/modules/Ordner/actions';

  import { Doc, CRDDocRequest } from '@/models/DocModels';
  //#endregion

  const props = defineProps({
    doc: {
      type: Object as PropType<Doc>,
      required: true,
    },
    archivid: {
      type: Number as PropType<number>,
      required: true,
    },
  });

  const store = useStore();

  const updateLoading = computed(() => store.getters.status.updateDocLoading);
  const updateErrorMsg = computed(() => store.getters.status.docErrorMsg);
  const showUpdateErrorMsg = ref(false);

  const updateDoc = ref(JSON.parse(JSON.stringify(props.doc)));

  function resetForm() {
    updateDoc.value = JSON.parse(JSON.stringify(props.doc));
  }

  const updateDocModalRef = ref();
  function closeUpdateModal() {
    resetForm();
    Modal.getInstance(updateDocModalRef.value)?.hide();
  }

  function showUpdateModal() {
    showUpdateErrorMsg.value = false;

    if (updateDocModalRef.value) {
      new Modal(updateDocModalRef.value).show();
    }
  }

  function saveChanges() {
    showUpdateErrorMsg.value = false;

    const request = new CRDDocRequest(updateDoc.value, props.archivid);

    store.dispatch(OrdnerActionTypes.UpdateDoc, request).then(() => {
      if (updateErrorMsg.value == null || updateErrorMsg.value == '') {
        closeUpdateModal();
      } else {
        showUpdateErrorMsg.value = true;
      }
    });
  }

  defineExpose({
    showUpdateModal,
  });
</script>
