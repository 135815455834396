<template>
  <div
    class="modal fade"
    :id="'uploadCompFileModal-' + compid"
    tabindex="-1"
    :aria-labelledby="'uploadCompFileModal-' + compid"
    aria-hidden="true"
    ref="updateCompModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header py-1">
          <span class="modal-title my-auto" :id="'uploadCompFileModalLabel-' + compid">Datei hochladen</span>
          <button type="button" class="btn-close" aria-label="Close" @click="closeModal()"></button>
        </div>
        <div class="modal-body">
          <LoadingSpinner v-if="loading" text="...Datei wird hochgeladen..." />

          <form v-else @submit.prevent="uploadFile()">
            <div class="mb-3">
              <label for="fileupload" class="form-label">Datei</label>
              <input type="file" class="form-control" id="fileupload" @change="handleFileUpload" />
            </div>

            <div v-if="fileSizeErrorMessage != ''" class="alert alert-danger mb-3">
              {{ fileSizeErrorMessage }}
            </div>

            <hr class="my-3" />

            <div v-if="errorMsg != ''" class="alert alert-danger mb-3">
              Es ist ein Fehler aufgetreten:<br />
              {{ errorMsg }}
            </div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-secondary me-3" @click="closeModal()">Abbrechen</button>
              <button type="submit" class="btn btn-primary">Speichern</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, ref, computed } from 'vue';
  import { useStore } from '@/store';
  import { Modal } from 'bootstrap';

  import { UploadCompFileData, UploadCompFileRequest } from '@/models/CompModels';

  import { ActionTypes as OrdnerActionTypes } from '@/store/modules/Ordner/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  //#endregion

  const props = defineProps({
    archivid: {
      type: Number as PropType<number>,
      required: true,
    },
    compid: {
      type: String as PropType<string>,
      required: true,
    },
  });

  const store = useStore();

  const loading = computed(() => store.getters.status.updateCompLoading);
  const errorMsg = computed(() => store.getters.status.compErrorMsg);

  const MAX_FILE_SIZE = 50 * 1024 * 1024;
  const file = ref<File | null>(null);
  const fileSizeErrorMessage = ref('');

  function handleFileUpload(event: Event) {
    fileSizeErrorMessage.value = '';

    const inputElement = event.target as HTMLInputElement;
    const selectedfile = inputElement.files![0];

    if (selectedfile) {
      file.value = selectedfile;
    }

    if (file.value != null && file.value.size > MAX_FILE_SIZE) {
      fileSizeErrorMessage.value = 'Die Anlagengröße überschreitet das erlaubte Maximum. Die maximale Größe ist 25MB.';
    }
  }

  const updateCompModalRef = ref();

  function closeModal() {
    file.value = null;
    Modal.getInstance(updateCompModalRef.value)?.hide();
  }

  function showModal() {
    if (updateCompModalRef.value) {
      new Modal(updateCompModalRef.value).show();
    }
  }

  function uploadFile() {
    fileSizeErrorMessage.value = '';

    if (file.value != null) {
      const data: UploadCompFileData = {
        compid: props.compid,
        file: file.value,
      };

      const request: UploadCompFileRequest = {
        data: data,
        archivid: props.archivid,
      };

      store.dispatch(OrdnerActionTypes.UploadCompFile, request).then(() => {
        if (errorMsg.value == '') {
          closeModal();
        }
      });
    } else {
      fileSizeErrorMessage.value = 'Bitte wählen Sie eine Datei aus.';
    }
  }

  defineExpose({
    showModal,
  });
</script>
